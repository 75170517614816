import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Icon from '../../assets/icons/dropdown-arrow-icon.svg'

const TableDropdown = (props) => {
  const { actions = [], handleChange } = props;
  return (
    <Dropdown onSelect={handleChange} className='table-dropdown'>
      <Dropdown.Toggle >
      <img className="dropdown-arrow-icon" src={Icon} alt='select' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='table-dropdown-menu'>
        {
          actions && actions?.length ?
            actions.map((action, index) => <Dropdown.Item eventKey={action} key={index} className='table-dropdown-item'>{action}</Dropdown.Item>)
            :
            <Dropdown.Item disabled eventKey="None"></Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TableDropdown;