import { useState } from "react";

import {
  emailValidator,
  passwordValidator,
  confirmPasswordValidator,
  textValidator,
  requiredValidator
} from "../helper/validator";

const touchErrors = errors => {
  return Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {});
};

export const useFormValidator = form => {
  const [errors, setErrors] = useState({
    email: {
      dirty: false,
      error: false,
      message: "",
    },
    firstName: {
      dirty: false,
      error: false,
      message: "",
    },
    lastName: {
      dirty: false,
      error: false,
      message: "",
    },
    birthDate: {
      dirty: false,
      error: false,
      message: "",
    },
    password: {
      dirty: false,
      error: false,
      message: "",
    },
    confirmPassword: {
      dirty: false,
      error: false,
      message: "",
    },
    phone: {
      dirty: false,
      error: false,
      message: "",
    },
    username: {
      dirty: false,
      error: false,
      message: "",
    },
    gender: {
      dirty: false,
      error: false,
      message: "",
    },
    resetCode: {
      dirty: false,
      error: false,
      message: "",
    },
    token: {
      dirty: false,
      error: false,
      message: "",
    },
    verificationCode: {
      dirty: false,
      error: false,
      message: "",
    },
    leagueName: {
      dirty: false,
      error: false,
      message: "",
    },
    teamName: {
      dirty: false,
      error: false,
      message: "",
    },
    role: {
      dirty: false,
      error: false,
      message: "",
    },
    teamJoinCode: {
      dirty: false,
      error: false,
      message: "",
    },

  });

  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {

    let isValid = true;

    // Create a deep copy of the errors
    var nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { email, password, confirmPassword, firstName, lastName, birthDate, gender, resetCode, username, token, phone, verificationCode, leagueName, teamName, role, teamJoinCode } = form;

    if (nextErrors.firstName.dirty && (field ? field === "firstName" : true)) {
      const firstNameMessage = textValidator(firstName, 'First Name');
      nextErrors.firstName.error = !!firstNameMessage;
      nextErrors.firstName.message = firstNameMessage;
      if (!!firstName) isValid = false;
    };

    if (nextErrors.lastName.dirty && (field ? field === "lastName" : true)) {
      const lastNameMessage = textValidator(lastName, 'Last Name');
      nextErrors.lastName.error = !!lastNameMessage;
      nextErrors.lastName.message = lastNameMessage;
      if (!!lastName) isValid = false;
    };

    if (nextErrors.username.dirty && (field ? field === "username" : true)) {
      const usernameMessage = requiredValidator(username, 'Username');
      nextErrors.username.error = !!usernameMessage;
      nextErrors.username.message = usernameMessage;
      if (!!username) isValid = false;
    };

    if (nextErrors.gender.dirty && (field ? field === "gender" : true)) {
      const genderMessage = requiredValidator(gender, 'Gender');
      nextErrors.gender.error = !!genderMessage;
      nextErrors.gender.message = genderMessage;
      if (!!gender) isValid = false;
    };


    if (nextErrors.birthDate.dirty && (field ? field === "birthDate" : true)) {
      const birthDateMessage = requiredValidator(birthDate, 'Birth Date');
      nextErrors.birthDate.error = !!birthDateMessage;
      nextErrors.birthDate.message = birthDateMessage;
      if (!!birthDate) isValid = false;
    };
    if (nextErrors.role.dirty && (field ? field === "role" : true)) {
      const roleMessage = requiredValidator(role, 'Role');
      nextErrors.role.error = !!roleMessage;
      nextErrors.role.message = roleMessage;
      if (!!role) isValid = false;
    };

    if (nextErrors.resetCode.dirty && (field ? field === "resetCode" : true)) {
      const resetCodeMessage = requiredValidator(resetCode, 'Reset Code');
      nextErrors.resetCode.error = !!resetCodeMessage;
      nextErrors.resetCode.message = resetCodeMessage;
      if (!!resetCode) isValid = false;
    };


    if (nextErrors.resetCode.dirty && (field ? field === "verificationCode" : true)) {
      const verificationCodeMessage = requiredValidator(resetCode, 'Verification Code');
      nextErrors.resetCode.error = !!verificationCodeMessage;
      nextErrors.resetCode.message = verificationCodeMessage;
      if (!!resetCode) isValid = false;
    };

    if (nextErrors.phone.dirty && (field ? field === "phone" : true)) {
      const phoneMessage = requiredValidator(phone, 'Phone');
      nextErrors.phone.error = !!phoneMessage;
      nextErrors.phone.message = phoneMessage;
      if (!!phone) isValid = false;
    };

    if (nextErrors.email.dirty && (field ? field === "email" : true)) {
      const emailMessage = emailValidator(email, form);
      nextErrors.email.error = !!emailMessage;
      nextErrors.email.message = emailMessage;
      if (!!emailMessage) isValid = false;
    };

    if (nextErrors.token.dirty && (field ? field === "token" : true)) {
      const tokenMessage = requiredValidator(token, 'Token');
      nextErrors.token.error = !!tokenMessage;
      nextErrors.token.message = tokenMessage;
      if (!!token) isValid = false;
    };

    if (nextErrors.leagueName.dirty && (field ? field === "leagueName" : true)) {
      const leagueNameMessage = textValidator(leagueName, 'League Name');
      nextErrors.leagueName.error = !!leagueNameMessage;
      nextErrors.leagueName.message = leagueNameMessage;
      if (!!leagueNameMessage) isValid = false;
    };
    if (nextErrors.teamName.dirty && (field ? field === "teamName" : true)) {
      const teamNameMessage = textValidator(teamName, 'Team Name');
      nextErrors.teamName.error = !!teamNameMessage;
      nextErrors.teamName.message = teamNameMessage;
      if (!!teamNameMessage) isValid = false;
    };
    if (nextErrors.teamJoinCode.dirty && (field ? field === "teamJoinCode" : true)) {
      const teamJoinCodeMessage = textValidator(teamJoinCode, 'Team Join Code');
      nextErrors.teamJoinCode.error = !!teamJoinCodeMessage;
      nextErrors.teamJoinCode.message = teamJoinCodeMessage;
      if (!!teamJoinCodeMessage) isValid = false;
    };

    if (nextErrors.password.dirty && (field ? field === "password" : true)) {
      const passwordMessage = passwordValidator(password, form);
      nextErrors.password.error = !!passwordMessage;
      nextErrors.password.message = passwordMessage;
      if (!!passwordMessage) isValid = false;
    };

    if (
      nextErrors.confirmPassword.dirty &&
      (field ? field === "confirmPassword" : true)
    ) {
      const confirmPasswordMessage = confirmPasswordValidator(
        confirmPassword,
        form.password
      );
      nextErrors.confirmPassword.error = !!confirmPasswordMessage;
      nextErrors.confirmPassword.message = confirmPasswordMessage;
      if (!!confirmPasswordMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = e => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ form, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
  };
};