import React, { useState, useEffect } from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Skeleton from '@mui/material/Skeleton';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';

function DashboardMainLayout({ title='', loading, children }) {

    const [isLoading, setLoading] = useState(loading);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return (
        <Container className="mb-5">
            <Row>
                <Col>
                    <Stack gap={3}>
                        <ListGroup className="mb-3 list-group-container">
                            {title &&
                                <ListGroup.Item variant="secondary" className="list-group-gradient-head">
                                    {title.toUpperCase()}
                                </ListGroup.Item>
                            }
                            <ListGroup.Item className="px-0 pt-0 lh-lg">
                                {
                                    isLoading &&
                                    <>
                                        <Skeleton animation="wave" className='p-5' />
                                    </>
                                }

                                {!isLoading && children}

                            </ListGroup.Item>
                        </ListGroup>
                    </Stack>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardMainLayout;