import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const ContainedButton = (props) => {
  const { title = 'Submit', buttonType, hoverColor = '#FFFFFF', isLoading = false, fill, handleClick } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
  }, []);

  let style = {};
  if (buttonType !== '') {
    switch (buttonType) {
      case 'search': style = {
        width: '130px',
        height: '28px',
        background: '#95D2EC',
        border: '1px solid #FFFFFF',
        borderRadius: '57px',
        fontWeight: 600,
        fontSize: '15px',
        fontFamily:'Montserrat',
        lineHeight: '20px',
        color: '#FFFFFF',
      }
        break;
      case 'modal': style = {
        minWidth: width >= 800 ? '200px' : '100px',
        minHeight: '28px',
        background: fill ? '#003F60' : '#FFFFFF',
        color: fill ? '#FFFFFF' : '#003F60',
        border: '1px solid #FFFFFF',
        borderRadius: '68px',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '16px',
        textAlign: 'center',
        padding: width >= 800 ? '4px 35px' : '4px 10px',
        marginLeft: '10px'
      }
        break;
      case 'send-email': style = {
        minWidth: '320px',
        minHeight: '35px',
        background: '#F65A4A',
        border: '1px solid #FFFFFF',
        borderRadius: '57px',
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#FFFFFF',
        '@media(max-width: 800px)': {
          minWidth: '200px',
        }
      }
        break;
      case 'pricing-green': style = {
        width: '150px',
        height: '28px',
        background: '#91D465',
        border: '1px solid #91D465',
        borderRadius: '68px',
        fontWeight: 600,
        fontSize: '15px',
        textAlign: 'center',
        justifyContent: 'center',
        color: '#0C243F',
      }
        break;
      case 'pricing-blue': style = {
        width: '150px',
        height: '28px',
        background: '#95D2EC',
        border: '1px solid #95D2EC',
        borderRadius: '68px',
        fontWeight: 600,
        fontSize: '15px',
        textAlign: 'center',
        alignItems: 'center',
        color: '#0C243F',
      }
      break;
      case 'contact': style = {
        maxWidth: '380px',
        height: 'auto',
        padding:'5px 30px',
        background: '#95D2EC',
        border: '1px solid #95D2EC',
        borderRadius: '68px',
        fontWeight: 600,
        fontSize: '18px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent:'center',
        color: '#003F60',
      }
        break;
    }
  }
  return (
    <LoadingButton
      sx={{
        ...style,
        fontFamily:'Montserrat',
        "&:hover": {
          color: hoverColor,
          backgroundColor: '#FFFFFF',
          border: `1px solid ${hoverColor}`,
        }
      }}
      loading={isLoading}
      onClick={handleClick}
    >
      <span>{title}</span>
    </LoadingButton >
  )
}

export default ContainedButton;