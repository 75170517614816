import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';

import TableButton from '../../components/buttons/TableButton';
import TableDropdown from '../../components/drop-down/TableDropdown';

import moment from 'moment';
import Container from 'react-bootstrap/Container';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";
import Loader from "../../components/modal/Loader";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

import { StyledTableGradientHead, StyledTableCell, StyledTableRow } from '../../assets/styles/SharedStyles';

const RosterArchives = () => {

  const { leagueId = '', teamId = '' } = useParams();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const gaEventTracker = useAnalyticsEventTracker('ROSTER-ARCHIVES');

  const [archivedMembers, setArchivedMembers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const actions = ['Test Scores', 'Profile', 'Restore', 'Remove'];
  
  useEffect(() => {
    getArchivedMembers();
  }, []);

  useEffect(() => {
    
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const getArchivedMembers = async () => {

    try {

      setLoading(true);

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_ARCHIVED_MEMBERS, inputParams);

      const data = response?.data;
      
      if (data?.status && data?.data) {
        setArchivedMembers(data?.data);
        setLoading(false);
        return;
      }
      else {
        if (data?.message) alert(data.message);
        setLoading(false);
        return;
      };
    }
    catch (err) {
      console.log(err);
      gaEventTracker('ERROR', 'ROSTER-ARCHIVES');
      setLoading(false);
    };
  };

  const handleAction = (event, action, param) => {

    event !== null && event.preventDefault();

    action = action.toLowerCase();

    switch (action) {
      case 'test scores':
        navigate(`/league/${leagueId}/team/${teamId}/member/${param.id}/user/${param.user}/test/results`)
        break;
      case 'profile':
        navigate(`/league/${leagueId}/team/${teamId}/member/${param.id}/profile/${param.user}`)
        break;
      case 'restore':
        if (window.confirm(`Do you wish to restore the player "${param?.firstName}"?`)) {
          handleRestoreMember(param);
        };
        break;
      case 'remove':
        if (window.confirm(`Do you wish to permanently remove the player "${param?.firstName}"?`)) {
          handleRemoveMember(param);
        };
        break;
    };
  };

  const handleRestoreMember = async (param) => {
    
    try {
      
      setLoading(true);
      
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        teamMemberId: param.id
      };
      
      const response = await postRequest(axiosPrivate, URLConstant.RESTORE_ARCHIVED_MEMBER, inputParams);

      const data = response?.data;
      
      if (data?.status) {
        alert(`"${param?.firstName}" has been restored successfully!`);
        await getArchivedMembers(); // update the archived members list.
        return;
      }
      else {
        if (data?.message) alert(data.message);
        setLoading(false);
        return;
      };
    }
    catch (err) {
      console.log(err);
      gaEventTracker('ERROR', 'ROSTER-ARCHIVES');
      setLoading(false);
    };
  };

  const handleRemoveMember = async (param) => {
    
    try {
      
      setLoading(true);
      
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        teamMemberId: param.id
      };
      
      const response = await postRequest(axiosPrivate, URLConstant.REMOVE_MEMBER, inputParams);

      const data = response?.data;
      
      if (data?.status) {
        alert(`"${param?.firstName}" has been removed successfully!`);
        await getArchivedMembers(); // update the archived members list.
        return;
      }
      else {
        if (data?.message) alert(data.message);
        setLoading(false);
        return;
      };
    }
    catch (err) {
      console.log(err);
      gaEventTracker('ERROR', 'ROSTER-ARCHIVES');
      setLoading(false);
    };
  };

  return (
    <Container className="mb-4">
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableGradientHead align="left" colSpan={3}>ROSTER ARCHIVES</StyledTableGradientHead>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell><span className={`table-header-text`}>NAME</span></StyledTableCell>
            <StyledTableCell><span className={`table-header-text`}>ARCHIVED AT</span></StyledTableCell>
            <StyledTableCell><span className={`table-header-text`}>ACTIONS</span></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {archivedMembers?.length ?
            archivedMembers.map((memberObj, index) => {
              return (
                <StyledTableRow key={'action_' + index}>
                  <StyledTableCell>{memberObj?.firstName} {memberObj?.lastName}</StyledTableCell>
                  <StyledTableCell>{memberObj?.archivedAt && moment(memberObj?.archivedAt).format('MMM DD, YYYY hh:mm A')}</StyledTableCell>
                  <StyledTableCell>
                    <div className='align-space-around'>
                      {width >= 900 ? actions.map((action, index) =>
                        <TableButton key={index} title={action} hoverColor='#003F60' handleClick={(event) => handleAction(event, action, memberObj)} />
                      ) :
                        <TableDropdown actions={actions} handleChange={(action) => handleAction(null, action, memberObj)} />
                      }
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
            :
            <StyledTableRow>
              <StyledTableCell colSpan={3} className='text-center'>No archived player records found.</StyledTableCell>
            </StyledTableRow>
          }
        </TableBody>
      </Table>
      <Loader isLoading={isLoading}></Loader>
    </Container>
  );
};

export default RosterArchives;