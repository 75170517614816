import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import CONSTANTS from "../constant/Constants";

const usePageTracking = () => {

  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
      if (!initialized) {
          // console.log('GA initialized');
          ReactGA.initialize(CONSTANTS.CONFIG.GA_TRACKING_ID);
      }
      setInitialized(true);
  }, []);

  useEffect(() => {
      if (initialized) {
          // console.log('GA pageView');
          ReactGA.pageview(location.pathname + location.search);
      }
  }, [initialized, location]);

};

export default usePageTracking;