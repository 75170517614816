import React from "react";
import { useDropzone } from "react-dropzone";
import DragDropIcon from "../../assets/icons/drag-drop-icon.png";

function Dropzone({ open, onDrop, accept, maxFiles, choosenFile }) {

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept,
      onDrop,
      maxFiles
    });

  return (
    <div className="container">
      <span className="mb-2 d-block dropzone-content">
        Drag and Drop the file here
        [OR]
        Click to select file
      </span>
      <div {...getRootProps({ className: !isDragReject ? "dropzone" : "dropzone-error" })}>
        <input {...getInputProps()} />

        {
          isDragActive
            ?
            (
              <span className={!isDragReject ? "dropzone-content dropzone-ondrop" : "dropzone-content"}>
                {isDragReject && <> This file is not supported </>}
                {!isDragReject && <> Release to drop the files here </>}
              </span>
            )
            :
            (
              <div>
                <img className="drag-drop-icon" src={DragDropIcon} alt="Edit" />
                <div>{ choosenFile?.path }</div>
              </div>
            )
        }

      </div>
    </div>
  );
}

export default Dropzone;