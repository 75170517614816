import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import DashboardMainLayout from "../../components/layouts/DashboardMainLayout";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";
import { useFormValidator } from "../../hooks/useFormValidator";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import BorderTextField from '../../components/textfields/BorderTextField';
import FormButton from '../../components/buttons/FormButton';

const UpdateTeamJoinCode = () => {

  const { leagueId = '', teamId = '' } = useParams();

  const gaEventTracker = useAnalyticsEventTracker('TEAM_JOIN_CODE');
  const axiosPrivate = useAxiosPrivate();

  const [form, setForm] = useState({ teamJoinCode: "" });
  const [isProcessing, setProcessing] = useState(false);
  const { errors, validateForm, onBlurField } = useFormValidator(form);

  useEffect(() => {
    getTeamInfo();
  }, []);

  const onUpdateField = e => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);
    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const getTeamInfo = async () => {

    try {
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM, inputParams);

      const data = response?.data;
      if (data?.status) {
        setForm({ teamJoinCode: data.data.joinCode });
        return
      }
      else {
        if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    validateForm({ form, errors, forceTouchErrors: true });

    if (errors?.teamJoinCode?.error) return;

    handleUpdateTeam();

  };
  const handleUpdateTeam = async () => {

    try {

      setProcessing(true);

      var inputParams = {
        ...form,
        leagueId: +leagueId,
        teamId: +teamId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.UPDATE_TEAM_JOIN_CODE, inputParams);

      const data = response?.data;
      if (data?.status) {
        gaEventTracker('SAVE_JOIN_CODE_BUTTON', 'TEAM_JOIN_CODE');
        setProcessing(false);
        alert('Join Code changed successfully!');
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
      gaEventTracker('ERROR', 'TEAM_JOIN_CODE');
    };
  };
  return (
    <DashboardMainLayout title="Manage Team Join Code">

      <Row className="m-4 justify-content-md-center">
        <Col lg={10}>
          <BorderTextField
            id="teamJoinCode"
            label="Team Join Code"
            placeholder="Enter Code"
            errorData={errors?.teamJoinCode}
            value={form.teamJoinCode}
            onChange={onUpdateField}
            onBlur={onBlurField}
          />
          <Stack direction="horizontal" gap={3}>
            <br></br>
          </Stack>
          <div className="d-flex flex-row-reverse mx-auto mt-2">
            <FormButton title="Save Join Code" handleSubmit={handleSubmit} isLoading={isProcessing} variant='white' skipCheckIcon/>
          </div>
          <Stack direction="vertical" gap={1}>
            <br></br>
            <p><b>Team Join Code Requirements:</b></p>
            <ul>
              <li>Must be 4 - 40 characters long</li>
              {/* <li>Must contain at least one number</li>
              <li>Must contain at least one letter</li> */}
            </ul>
          </Stack>
        </Col>
      </Row>

    </DashboardMainLayout>
  );
};

export default UpdateTeamJoinCode;