import React, { Suspense, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import CONSTANTS from "../../constant/Constants";
import URLConstant from "../../constant/URLConstant";

import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import axiosClient, { postRequest } from "../../helper/axios";
import FormLayout from '../../components/layouts/FormLayout';
import FormButton from '../../components/buttons/FormButton';

import HitAppIcon from '../../assets/images/hitcheck-app-icon.png';

const BorderLinearProgress = styled(LinearProgress)(({}) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#FFF',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#91d465',
  },
}));

const ProcessOrder = (props) => {

  const navigate = useNavigate();
  const { setUserCoreProps } = useAuth();

  const queryString = window.location.search;
  const queryParams = Object.fromEntries([...(new URLSearchParams(queryString))]);

  const [isFailed, setFailedStatus] = useState(false);
  const [userSubscriptionData, setUserSubscriptionData] = useLocalStorage(CONSTANTS.ASYNC_STORAGE_KEYS.USER_SUBSCRIPTION_DATA, null);

  useEffect(() => {
    
    try {
      if (queryParams?.success === 'true' && queryParams.stripeSessionId && queryParams.paymentRequestId) {
        initiateOrderProcess(queryParams);
      }
      else {
        setFailedStatus(true);
      };
    }
    catch (error) {
      console.log('Data not available!', error);
      navigateOnFailure();
    };
  }, []);

  const navigateOnFailure = () => {
    setUserSubscriptionData(null)
    window.location = '/subscription-plans';
  };

  const handleTryAgain = () => {
    setUserSubscriptionData(null)
    window.location = '/subscription-plans';
  };

  const initiateOrderProcess = async (queryParams) => {

    const response = await postRequest(axiosClient, URLConstant.HANDLE_SUBSCRIPTION + queryString, {});
    const data = response?.data;

    if (data?.data) {
      await setUserSubscriptionData(null);
      await setUserCoreProps(data.data);
      // Navigate to dashboard page.
      navigate("/user/dashboard", { replace: true });
    }
    else {
      if (data?.message) {
        alert(data?.message);
      }
      navigateOnFailure();
    };
  };

  return (
    <FormLayout>
      <Suspense fallback={<Skeleton count={5} />}>
        <Row className="my-5 justify-content-md-center">
          { !isFailed ?
              <Col lg={12}>
                <div style={{textAlign:'center'}} className="my-5">
                  <img src={HitAppIcon} className="hit-app-icon" alt="HITCHECK" />
                </div>
                <span className='my-4 d-flex intruction-text justify-content-center'>Almost there, we are setting up your account...</span>
                <p><BorderLinearProgress /></p>
              </Col>
            :
              <Col lg={8} >
                <span className='my-4 d-flex intruction-text justify-content-center'>Order Status:<span className='ps-2 intruction-text-green'>Failed!</span></span>
                <p className="legal-note text-center">We’re sorry, there was an issue with your order.</p>
                <Stack direction="vertical" gap={3} className="mx-auto mt-5 mb-5">
                  <FormButton handleSubmit={handleTryAgain} title="RETURN TO PRICING PAGE" skipCheckIcon />
                </Stack>
              </Col>
          }
        </Row>
      </Suspense>
    </FormLayout>
  )
}

export default ProcessOrder;