import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';

export default {

  whiteButton: {
    minWidth: 100,
    color: '#F65A4A',
    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    borderRadius: '50px',
    fontWeight: 'bold',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    lineHeight: '12px',
    padding: '5px 15px',
    "&:hover": {
      color: '#F65A4A',
      background: '#FFFFFF',
      border: '1px solid #FFFFFF',
    }
  },
};

export const StyledTableGradientHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'linear-gradient(133.34deg, #0C243F 24.1%, #003F60 82.5%)',
    color: theme.palette.common.white,
    border: '1px solid #003F60',
    fontFamily: 'Montserrat',
    fontWeight: '700',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 140,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#003F60',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Montserrat-Medium',
    borderRight: '1px solid #003F60',
    borderBottom: '1px solid #003F60',
    borderCollapse: 'collapse',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    color: '#003F60',
    borderRight: '1px solid #003F60',
    borderBottom: '1px solid #003F60',
  },
  '&:first-child': {
    borderLeft: '1px solid #003F60',
  }
}));
  
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));