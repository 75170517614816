import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Box from '@mui/material/Box';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";

import Icon from '../../assets/icons/send-email-icon.svg';
import ModalLayout from './ModalLayout';

const ReminderModal = (props) => {
  const { open = false, recipientsText, message, handleClose, param } = props;
  const { leagueId = '' } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
  }, [])

  const handleEmailReminder = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      var inputParams = {
        teamId: param.teamId,
        leagueId: +leagueId,
        reminderType: param.reminderType,
      };

      const response = await postRequest(axiosPrivate, param?.sendReminderAPI, inputParams);

      const data = response?.data;
      if (data?.status) {
        setLoading(false);
        alert(data.message);
        handleClose();
        return
      }

    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }
  return (
    <ModalLayout
      open={open}
      onClick={handleEmailReminder}
      title='SEND EMAIL REMINDER'
      isLoading={isLoading}
      handleClose={handleClose}
      buttonText={['CANCEL', 'CONFIRM & SEND']}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: width >= 800 ? 'row' : 'column',
        alignItems: 'center',
        padding: width >= 800 ?'25px 55px 0 35px':'25px 35px 10px 35px',
      }}>
        <img className="modal-icon" src={Icon} alt='SEND EMAIL' />
        <div>
          <span className="confirm-action-text">
            Please confirm you would like to send the following email reminder:
          </span>
          <span className="modal-sub-title">
            Recipients:
          </span>
          <p className="modal-text">
            {recipientsText?.text1}
            <span className="modal-text-bold">{recipientsText?.text2}</span>
            {recipientsText?.text3}
          </p>
          <span className="modal-sub-title">
            Message:
          </span>
          <p className="modal-text">
            {message}
          </p>
        </div>
      </Box>
    </ModalLayout>
  )
}

export default ReminderModal;