import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import _ from 'lodash';

import { currencyFormatter } from '../../../helper/util';
import ContainedButton from '../../../components/buttons/ContainedButton';
import Constants from '../../../constant/Constants';

import CheckIcon from '../../../assets/icons/checkmark-icon.png';

const PlanCard = (props) => {

  const navigate = useNavigate();
  const [showfeatures, setShowFeatures] = useState({});
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  let { features, subscriptionPlans, orgType, memberCount, updateContactFormVisibility } = props;

  if (!features || (features && features.length == 0)) return null;
  else if (!subscriptionPlans || (subscriptionPlans && subscriptionPlans.length == 0)) return null;

  subscriptionPlans = _.filter(subscriptionPlans, (item) => item.targetHierarchy != 'solo'); // No need to display solo-subscription plans for now since it will be free.
  let mostPopularPlanInd = _.findIndex(subscriptionPlans, { isMostPopular: true });

  const handleSubmit = (planId) => {

    if (!planId) {
      return alert('Invalid Subscription Plan');
    };

    if (planId == 1) {
      return navigate(`/sign-up?subscription-plan-id=${planId}`);
    };

    if (!memberCount || (memberCount && memberCount < 0)) {
      return alert('Invalid Headcount');
    }
    else if (!orgType) {
      return alert('Invalid Organization Type');
    }
    else {
      return navigate(`/sign-up?subscription-plan-id=${planId}&head-count=${memberCount}&org-type-id=${orgType}`);
    };
  };

  const renderHeader = (plan, planInd) => {
    
    let planDescription = plan?.description.split('+');

    return (
      !plan?.volumeDiscount ?
        <div className={`plan-card-container ${planInd === mostPopularPlanInd ? width <= '992' ? 'popular-plan-mobile-view' : 'popular-plan' : ''}`}
          style={{ borderTopRightRadius: (subscriptionPlans.length - 1) == planInd ? 45 : 0, paddingTop: planInd !== mostPopularPlanInd ? width >= '992' && '30px' : '0' }}>
          {plan?.isMostPopular && <span className='popular'>Most Popular</span>}
          <span className='title'>{plan?.title}</span>
          <b className='cost'>{plan?.currency === 'USD' && '$'}{' '}{plan?.costPerUser}</b>
          <p className='term'>{`per ${orgType === 1 ? 'athlete' : 'patient'}/${plan?.termUnit}`}</p>
          <b className='total'>{memberCount ? `Total: ${currencyFormatter(plan?.finalPrice)}` : ' '}</b>
          <ContainedButton title="Get Started" buttonType="pricing-green" hoverColor='#0C243F' handleClick={() => handleSubmit(plan?.id)} />
          {/* {width <= '992' && <span className='header-sub-text pt-3 pb-1'>{plan?.description}</span>} */}
          {width <= '992' ?
            planDescription[1] ?
              <>
                <span className='pt-2 header-text'>{`${planDescription[0]} + `}</span>
                <span className='py-1 header-sub-text'>{planDescription[1] ? planDescription?.shift() && planDescription?.join(" + ") : null}</span>
              </>
              : <span className='pt-2 header-text'>{planDescription[0]}</span>
            : null}
        </div>
        :
        <div className={`plan-card-container container-background-lite ${planInd === mostPopularPlanInd ? width <= '992' ? 'popular-plan-mobile-view' : 'popular-plan' : ''}`}
          style={{ borderTopRightRadius: (subscriptionPlans.length - 1) == planInd && width >= '992' ? 45 : 0, paddingTop: planInd !== mostPopularPlanInd ? width >= '992' && '30px' : '0' }}>
          {plan?.isMostPopular && <span className='popular'>Most Popular</span>}
          <span className='title'>{plan?.title}</span>
          <b className='custom-plan-text'>{Constants.CUSTOM_SUBSCRIPTION_TEXT}</b>
          <p className='custom-plan-sub-text'>{Constants.CUSTOM_SUBSCRIPTION_PRICING_TEXT}</p>
          <ContainedButton title="Let's Talk" buttonType="pricing-blue" hoverColor='#0C243F' handleClick={() => updateContactFormVisibility()} />
          {width <= '992' ?
            planDescription[1] ?
            <>
              <span className='pt-2 header-text'>{`${planDescription[0]} + `}</span>
              <span className='py-1 header-sub-text'>{planDescription[1] ? planDescription?.shift() && planDescription?.join(" + ") : null}</span>
            </>
            : <span className='pt-2 header-text'>{planDescription[0]}</span>
          : null}
        </div>
    );
  };

  const renderPlanFeatures = (plan, planInd) => {
    return (
      features.map((feature, featureInd) =>
        <Row className='m-0 feature-row' key={featureInd}>
          <Col xs={9} s={9} md={9} className='plan-feature-cell'>
            <span className='feature'>{feature?.title}</span>
          </Col>
          <Col xs={3} s={3} md={3}
            key={featureInd + '-' + planInd}
            className={`plan-cell`}
            style={{
              borderRight: planInd === (mostPopularPlanInd - 1) && 'none',
              background: plan.volumeDiscount ? '#d0e4ec' : '#FFFFFF',
            }}>
            {plan[feature?.id] || (plan[feature?.id] === null && feature?.acceptNull) ?
              <img src={CheckIcon} className="check-icon" alt="check" /> :
              <span className='dash'>-</span>
            }
          </Col>
        </Row>

      )
    )
  }

  return (
    width >= '992' ? <Container className='tier-pricing-container'>
      <Row className='m-0 plan-details-container'>
        <Col lg={3} className='p-0 plan-card-container' />
        <Col lg={9} className='p-0'>
          <Row className='m-0'>
            {subscriptionPlans.map((plan, planInd) =>
              <Col lg={3} className='p-0' key={'header_' + planInd}>
                {renderHeader(plan, planInd)}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className='m-0 feature-row'>
        <Col lg={3} className='py-1 header' />
        <Col lg={9} className='p-0'>
          <Row className='m-0'>
            {subscriptionPlans.map((plan, planInd) => {
              let planDescription = plan?.description.split('+');
              return <Col lg={3} key={'desc_' + planInd}
                className={`px-2 header ${planInd === mostPopularPlanInd ? 'popular-plan' : ''}`}
                style={{
                  borderRight: planInd === (mostPopularPlanInd - 1) && 'none',
                  background: plan.volumeDiscount ? '#d0e4ec' : '#eeeeee',
                }}>
                {planDescription[1] ?
                  <>
                    <span className='pt-2 header-text'>{`${planDescription[0]} + `}</span>
                    <span className='py-1 header-sub-text'>{planDescription[1] ? planDescription?.shift() && planDescription?.join(" + ") : null}</span>
                  </>
                  : <span className='pt-2 header-text'>{planDescription[0]}</span>
                }
              </Col>
            }
            )}
          </Row>
        </Col>
      </Row>
      {features.map((feature, featureInd) =>
        <Row className='m-0 feature-row' key={featureInd}>
          <Col lg={3} className='p-3 plan-feature-cell'>
            <span className='feature'>{feature?.title}</span>
          </Col>
          <Col lg={9} className='p-0'>
            <Row className='m-0'>
              {subscriptionPlans.map((plan, planInd) =>
                <Col lg={3}
                  key={featureInd + '-' + planInd}
                  className={`p-3 plan-cell ${planInd === mostPopularPlanInd ? 'popular-plan' : ''}`}
                  style={{
                    borderRight: planInd === (mostPopularPlanInd - 1) && 'none',
                    background: plan.volumeDiscount ? '#d0e4ec' : '#FFFFFF',
                  }}>
                  {plan[feature?.id] || (plan[feature?.id] === null && feature?.acceptNull) ?
                    <img src={CheckIcon} className="check-icon" alt="check" /> :
                    <span className='dash'>-</span>
                  }
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </Container>
      :
      <Container className='m-0 tier-pricing-mobile-container'>
        {subscriptionPlans.map((plan, planInd) => <><Row className='mb-5 mx-5'>
          <Col lg={3} className='p-0 mx-2' key={'header_' + planInd}>
            {renderHeader(plan, planInd)}
            <div className='features-container' onClick={(event) => {
              event.preventDefault();
              setShowFeatures({ ...showfeatures, [`plan_${plan.id}`]: !showfeatures[`plan_${plan.id}`] })
            }}>
              <span className='features-button'>{showfeatures[`plan_${plan.id}`] ? '-' : '+'}</span>
              <span className='features-text'>{showfeatures[`plan_${plan.id}`] ? 'Minimize features' : 'View Features'}</span>
            </div>
            {showfeatures[`plan_${plan.id}`] &&
              <div className='plan-features-table-mobile'>
                {renderPlanFeatures(plan, planInd)}
              </div>}
          </Col>
        </Row>
        </>
        )}
      </Container>
  );
};

export default PlanCard;