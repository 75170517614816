import React from "react";
import { NavLink, Outlet, useParams } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';

import { useAuth } from "../../hooks/useAuth";

const TeamLayout = () => {

  const { user } = useAuth();
  const { leagueId = '', teamId = '' } = useParams();

  const menuItem = {
    grandParentMenu: {
      title: 'League',
      href: leagueId === '' ? '#' : `/league/${leagueId}/`,
    },
    parentMenu: {
      title: 'Team',
      href: leagueId === '' ? '#' : `/league/${leagueId}/team/${teamId}/`,
    },
  };

  const selectedNavLink = ({ isActive }) => isActive ? 'list-group-item list-group-item-action list-group-item-primary' : 'list-group-item list-group-item-action';

  return (
    <Container className="mt-3 p-2">
      
      <Row >
        <Col xs={12} s={12} md={12} lg={9} xl={9} xxl={9} className="mt-2">
          <Outlet />
        </Col>
        <Col xs={12} s={12} md={12} lg={3} xl={3} xxl={3} className="mt-2">
          <Stack gap={3}>
            <ListGroup className="mb-5 list-group-container">
              <ListGroup.Item variant="secondary"className="list-group-gradient-head">
                Team Actions
              </ListGroup.Item>
              <NavLink to={`/league/${leagueId}/team/${teamId}/`} className={selectedNavLink} >
                Team Dashboard
              </NavLink>
            </ListGroup>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamLayout;