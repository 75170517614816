import React from 'react';
import Button from '@mui/material/Button';

const BorderButtonWithLink = (props) => {
  const { title = 'Submit', handleClick, colorCode, selected = false } = props;
  return (
    <Button
      sx={{
        minWidth: 100,
        color: selected ? colorCode : '#FFFFFF',
        background: selected && '#FFFFFF',
        border: '1px solid #FFFFFF',
        borderRadius: '68px',
        fontWeight: '400',
        fontSize: '12px',
        fontFamily:'Montserrat',
        lineHeight: '12px',
        padding: '5px 0',
        // alignItems: 'center',
        // verticalAlign: 'middle',
        "&:hover": {
          color: colorCode,
          background: '#FFFFFF',
          border: `1px solid #FFFFFF`,
        }
      }}
      variant="outlined"
      size='small'
      onClick={handleClick}
      rounded >
      {selected ? 'SELECTED' : title}
    </Button >
  )
}

export default BorderButtonWithLink;