import React from "react";
import Box from '@mui/material/Box';
import Backdrop from "@mui/material/Backdrop";
import ContainedButton from "../buttons/ContainedButton";

const style = {
  rootContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    minWidth: '300px',
    maxWidth:'95%',
    paddingBottom: '25px',
  },
  headercontainer: {
    display: 'flex',
    bgcolor: '#003F60',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'space-between',
    verticalAlign: 'middle',
    padding: '0 20px'
  },
  closeIconContainer: {
    width: 20,
    height: 20,
    border: '1.5px solid #fff',
    borderRadius: '50%',
    padding: 2,
    fontSize: 10,
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    cursor: 'pointer',
    
  }
};

const ModalLayout = (props) => {
  
  const { open = false, children, title = '', isLoading = false, onClick, handleClose, buttonText, showCloseIcon } = props;

  return (
    <Backdrop
      sx={{
        color: "green",
        background: "rgba(1, 46, 69, 0.8)",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={open}
      // onClick={handleClose}
      >
      <Box sx={style.rootContainer}>
        <Box sx={style.headercontainer} >
          <div><span className="modal-title">{title}</span></div>
          {showCloseIcon && <div style={style.closeIconContainer} onClick={handleClose}>X</div>}
        </Box>
        {children}
        {buttonText && buttonText.length > 0 && (
          <div className="modal-button-container">
            <ContainedButton title={buttonText[0]} buttonType='modal' hoverColor='#003F60' handleClick={handleClose} fill={false} />
            <ContainedButton title={buttonText[1]} buttonType='modal' hoverColor='#003F60' handleClick={onClick} isLoading={isLoading} fill />
          </div>
        )}
      </Box>
    </Backdrop>
  )
}

export default ModalLayout;