import React, { Suspense, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import Checkbox from '@mui/material/Checkbox';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BTable from 'react-bootstrap/Table';

import { currencyFormatter } from '../../helper/util';
import FormLayout from '../../components/layouts/FormLayout';
import FormButton from '../../components/buttons/FormButton';

const OrderSummary = (props) => {

  const { orderSummary, handleBack } = props;  
  const [ acceptTermsAndCondition, setAcceptTermsAndCondition ] = useState(true);

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    
    if (acceptTermsAndCondition) {
      props.handleSubmit();
    }
    else {
      return alert("Please accept \"Subscription Policy Agreement\" to continue.");
    };
  };

  return (
    <div className="mt-4 mx-2 mx-sm-3 checkout-wrapper text-center">
      <div className='borderless-table'>
        <BTable>
          <tbody>
            {orderSummary?.name &&
              <tr>
                <td className='field-heading'>Name</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{orderSummary?.name}</td>
              </tr>
            }
            {orderSummary?.email &&
              <tr>
                <td className='field-heading'>Email</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{orderSummary?.email}</td>
              </tr>
            }
            {orderSummary?.title &&
              <tr>
                <td className='field-heading'>Plan</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{orderSummary?.title}</td>
              </tr>
            }
            {orderSummary?.costPerUser &&
              <tr>
                <td className='field-heading'>Cost Per User</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{currencyFormatter(orderSummary?.costPerUser)}</td>
              </tr>
            }
            {orderSummary?.headCount &&
              <tr>
                <td className='field-heading'># of Athletes</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{orderSummary?.headCount}</td>
              </tr>
            }
            {orderSummary?.totalPrice &&
              <tr>
                <td className='field-heading'>Total</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{currencyFormatter(orderSummary?.totalPrice || 0)}</td>
              </tr>
            }
            {orderSummary?.appliedPromoCode &&
              <tr>
                <td className='field-heading'>Promo Code</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{orderSummary?.appliedPromoCode}</td>
              </tr>
            }
            {orderSummary?.discountDescription &&
              <tr>
                <td className='field-heading'>Discount</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{orderSummary?.discountDescription}</td>
              </tr>
            }
            {orderSummary?.appliedPromoCode && orderSummary?.discountDescription &&
              <tr>
                <td className='field-heading'>Payable Amount</td>
                <td className='field-heading'>:</td>
                <td className='field-value'>{currencyFormatter(orderSummary?.payableAmountAfterDiscount || 0)}</td>
              </tr>
            }
          </tbody>
        </BTable>
      </div>
      <div className="mx-auto mt-5 mb-5 text-center">
        <label className={!acceptTermsAndCondition ? "label-error" : ""}>
          <Checkbox name={"termsNdCondition"} defaultChecked color="success" onChange={() => setAcceptTermsAndCondition(!acceptTermsAndCondition)} />
          <span className="hitcheck-link-text">I accept <a href="https://www.hitcheck.com/privacy-policy" className="hitcheck-link-text-blue" target="_blank">Subscription Policy Agreement</a></span>
        </label>
        <div className='my-2 d-flex align-center flex-wrap'>
          <div className='m-3'>
            <FormButton handleSubmit={handleBack} title="Edit Order" skipCheckIcon />
          </div>
          <div className='m-3'>
            <FormButton handleSubmit={handleSubmit} title="Continue" skipCheckIcon />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderSummary;