import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import Icon from '../assets/icons/search-icon.svg'
import ContainedButton from "./buttons/ContainedButton";

const Search = (props) => {
  const { label = '', onClick, defaultSearchFilters = {} } = props;
  const [searchText, setSearchText] = useState(props.searchText);
  const [searchFilter, setSearchFilters] = useState(defaultSearchFilters);

  useEffect(() => {
    setSearchFilters(props.defaultSearchFilters);
  }, [defaultSearchFilters])


  return (
    <Container className="search-container">
      <div className="search-box-container">
        <img className="search-icon" src={Icon} alt='Search' />
        <Form className="search-form">
          <Form.Control className="search-box" type="text" value={searchText} placeholder={label} onChange={(event) => setSearchText(event.target.value)} onKeyDown={(e)=>e.key==='Enter' && onClick(e, searchText)}/>
        </Form>
        <ContainedButton title="SEARCH" buttonType="search" hoverColor='#95D2EC' handleClick={(event) => onClick(event, searchText)} />
      </div>
      <div className='search-filter'>
        <Form className="search-form">
          <Form.Label className="search-checkbox-label">SEARCH FILTERS:</Form.Label>
          <Form.Check id="search-checkbox-1" type="checkbox" label="BASELINE NEEDED" onChange={(e) => props.checkBoxOnChangeHandler(e, 'baseline-tests-needed')} checked={searchFilter['baseline-tests-needed']} inline ></Form.Check>
          <Form.Check id="search-checkbox-3" type="checkbox" label="BENCHED" onChange={(e) => props.checkBoxOnChangeHandler(e, 'players-benched')} checked={searchFilter['players-benched']} inline />
          <Form.Check id="search-checkbox-2" type="checkbox" label="POST-INJURY TAKEN" onChange={(e) => props.checkBoxOnChangeHandler(e, 'post-injury-tests-completed')} checked={searchFilter['post-injury-tests-completed']} inline />
        </Form>
      </div>
    </Container>
  )
}

export default Search;