import React, { useState } from "react";
import { useParams } from "react-router";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";

import Loader from "./Loader";
import ModalLayout from './ModalLayout';
import BorderButtonClickable from "../buttons/BorderButtonClickable";

const ResetTestModal = (props) => {

  const { leagueId , teamId } = props;
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setLoading] = useState(false);
  const [showResetTestModal, setShowResetTestModal] = useState(false);

  const handleResetTestData = async () => {
    
    setLoading(true);
    setShowResetTestModal(false);

    try {
      
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const apiURL = teamId ? URLConstant.RESET_TEAM_TEST_DATA : URLConstant.RESET_LEAGUE_TEST_DATA;
      const response = await postRequest(axiosPrivate, apiURL, inputParams);
      const data = response?.data;

      if (data?.message) alert(data.message);

      setLoading(false);
    }
    catch (err) {
      console.log(err);
      setLoading(false);
    };
  };

  return (
    <div>
      <Loader isLoading={isLoading} />
      <BorderButtonClickable title='RESET TEST DATA' size="small" handleClick={() => setShowResetTestModal(true)} />
      <ModalLayout
        open={showResetTestModal}
        title="Confirm Action!"
        onClick={() => handleResetTestData()}
        handleClose={() => setShowResetTestModal(false)}
        buttonText={['cancel', 'confirm']}>
        <div className='px-4 pt-3 modal-text' style={{ fontSize: 18 }}>Are you sure you want to reset test data of all athletes?</div>
        <div className='px-4 pb-3 modal-text' style={{ fontSize: 14 }}>Note: This action will reset all types of tests taken by the athletes and it cannot be undone. Any benched players will be unbenched and they will also be asked to take baseline again.</div>
      </ModalLayout>
    </div>
  );
}

export default ResetTestModal;