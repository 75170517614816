import { Navigate, Outlet, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';

import { useAuth } from "../../hooks/useAuth";
import { useUser } from "../../hooks/useUser";

import Constants from "../../constant/Constants";

const GradientPublicLayout = () => {

  const { user } = useAuth();
  const location = useLocation();

  const { leagues, teams } = useUser();
  
  if (user) {

    if (!user.isEmailVerified && !user.isPhoneNumberVerified) { // Force user to verify atleast their email
      return <Navigate to="/verify-email" replace />;
    }
    else if (leagues && teams) { // setUserCoreProps will set user data. So, it is important to wait until leagues and teams data populated from loadDashboardInfo API.
      
      if (location.state?.from) {
        return <Navigate to={location.state?.from} replace />;
      }
      else {
        return <Navigate to="/user/dashboard" replace />;
      };
    };
  };

  return (
    <Container fluid='xll' className="main-gradient-section">
      <Outlet />
    </Container>
  )
};

export default GradientPublicLayout;