import axios from 'axios';
import CONSTANTS from "../constant/Constants";
var moment = require('moment-timezone');

export const axiosPrivate = axios.create({
    baseURL: CONSTANTS.CONFIG.BACKEND_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

const axiosClient = axios.create({
    baseURL: CONSTANTS.CONFIG.BACKEND_BASE_URL, // Environment specific backend URL
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
}
);

export default axiosClient;

export function setAuthToken(token) {
    if (token) axiosClient.defaults.headers['Authorization'] = `Bearer ${token}`
    else if(axiosClient?.defaults?.headers?.Authorization && !token) {
        delete axiosClient.defaults.headers['Authorization'];
    }
    // console.log('set axiosClient.defaults.headers',token, axiosClient.defaults.headers)
    return;
};

export function getRequest(_axios = axiosClient, URL) {
    var timezone = moment.tz.guess();
    return _axios.get(`/${URL}?timezone=${timezone}`).then(response => response);
};

export function postRequest(_axios = axiosClient, URL, payload = {}) {
    payload.timezone = moment.tz.guess();
    return _axios.post(`/${URL}`, JSON.stringify(payload)).then(response => response);
};

export function postForm(_axios = axiosClient, URL, formData) {
    var timezone = moment.tz.guess();
    return _axios.postForm(`/${URL}?timezone=${timezone}`, formData).then(response => response);
};