import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import URLConstant from "../../constant/URLConstant";

import Container from 'react-bootstrap/Container';
import Loader from "../../components/modal/Loader";
import GradientTable from "../../components/table/GradientTable";

var momentTZ = require('moment-timezone')

const ListMembersForApproval = () => {

	const { leagueId = '', teamId = '' } = useParams();

	const gaEventTracker = useAnalyticsEventTracker('LIST_TEAM_MEMBERS');
	const axiosPrivate = useAxiosPrivate();
	const [members, setMembers] = useState([]);
	const [isMembersUpdated, setIsMembersUpdated] = useState(false);

	const [isProcessing, setProcessing] = useState(false);
	
	useEffect(() => {
		getMembersForApproval();
	}, [isMembersUpdated]);

	const getMembersForApproval = async () => {

		try {

			setProcessing(true);

			var inputParams = {
				teamId: +teamId,
				leagueId: +leagueId,
				timezone: momentTZ.tz.guess()
			};

			const response = await postRequest(axiosPrivate, URLConstant.GET_MEMBERS_FOR_APPROVAL, inputParams);
			const data = response?.data;

			if (data?.status) {
				setProcessing(false);
				setMembers(data.data);
				setIsMembersUpdated(false);
				return;
			}
			else {
				if (data?.message) alert(data.message);
				setProcessing(false);
				setIsMembersUpdated(false);
				return;
			};
		}
		catch (err) {
			console.log(err)
		};
	};

	const approveMember = async (param) => {

		var userConfirmation = window.confirm(`Are you sure you want to approve ${param?.firstName}'s join request?`);

		if (!userConfirmation) return false;

		setProcessing(true);

		try {

			var inputParams = {
				teamId: +teamId,
				leagueId: +leagueId,
        teamMemberId: param?.teamMemberId,
			};

			const response = await postRequest(axiosPrivate, URLConstant.APPROVE_MEMBER, inputParams);
			const data = response?.data;

			if (data?.status) {
				alert(`${param?.firstName}'s join request has been approved.`);
				setIsMembersUpdated(true);
				setProcessing(false);
				return;
			}
			else {
				if (data?.message) alert(data.message);
				setProcessing(false);
				return;
			};
		}
		catch (err) {
			console.log(err)
		};
	};

	const denyMember = async (param) => {

		var userConfirmation = window.confirm(`Are you sure you want to deny ${param?.firstName}'s join request?`);

		if (!userConfirmation) return false;

		setProcessing(true);

		try {

			var inputParams = {
				teamId: +teamId,
				leagueId: +leagueId,
        teamMemberId: param?.teamMemberId,
			};

			const response = await postRequest(axiosPrivate, URLConstant.REMOVE_MEMBER, inputParams);
			const data = response?.data;
			
			if (data?.status) {
				alert(`${param?.firstName}'s join request has been denied.`);
				setIsMembersUpdated(true);
				setProcessing(false);
				return;
			}
			else {
				if (data?.message) alert(data.message);
				setProcessing(false);
				return;
			};
		}
		catch (err) {
			console.log(err)
		};
	};

	const handleAction = (event, action, param) => {
		
		event.preventDefault();
		
		if (action === 'approve') {
			approveMember(param);
		}
		else if (action === 'deny') {
			denyMember(param);
		};
	};

	return (
		<Container className="hit-main-layout">
			<Row>
				<Col xs={12} s={12} md={12} lg={12} xl={12} xxl={12} className='mb-4'>
					<GradientTable
						title="Members Awaiting For Approval" headList={['Name', 'Actions']} rows={members}
						actions={['Approve', 'Deny']}  //buttons passed as array
						onAction={handleAction} />
				</Col>
			</Row>
			<Stack gap={3} />
			<Loader isLoading={isProcessing} />
		</Container>
	);
};

export default ListMembersForApproval;