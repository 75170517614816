import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import TableButton from '../buttons/TableButton';
import TableDropdown from '../drop-down/TableDropdown';

const StyledTableGradientHead = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: 'linear-gradient(133.34deg, #0C243F 24.1%, #003F60 82.5%)',
    color: theme.palette.common.white,
    border: '1px solid #003F60',
    fontFamily:'Montserrat',
    fontWeight:'700',

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#003F60',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily:'Montserrat',
    borderRight: '1px solid #003F60',
    borderBottom: '1px solid #003F60',
    borderCollapse: 'collapse',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
    fontFamily:'Montserrat',
    fontWeight:'500',
    color: '#003F60',
    borderRight: '1px solid #003F60',
    borderBottom: '1px solid #003F60',
  },
  '&:first-child': {
    borderLeft: '1px solid #003F60',
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const GradientTable = (props) => {

  const { title = 'Player', headList = [], rows = [], actions = [], roles = [], isLoading = false, onAction, onAdd, onArchive, slug = '' } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const getParam = (action, member) => {
    switch (action) {
      case 'approve':
        return { teamMemberId: member.teamMemberId, firstName: member.firstName };
      case 'remove':
        if (member.leagueMemberId) {
          return { leagueMemberId: member.leagueMemberId };
        } else if (member.teamMemberId) {
          return { teamMemberId: member.teamMemberId };
        }
        break;
      case 'deny':
          return { teamMemberId: member.teamMemberId, firstName: member.firstName };
      case 'test scores':
        return { userID: member.userID, teamMemberId: member.teamMemberId };
      case 'profile':
        return { userID: member.userID, teamMemberId: member.teamMemberId };
      case 'change role':
        return { teamMemberId: member.teamMemberId };
      case 'view':
        return { id: member?.id ? member.id : member?.teamId && member.teamId, joinCode: member.joinCode };
      case 'contact':
        if (slug.toLowerCase() === 'baseline-tests-needed') {
          return { reminderType: 'REMINDER_TO_TEAM_ADMIN_ABOUT_BASELINE_NEEDED', teamId: member.teamId };
        } else if (slug.toLowerCase() === 'pending-join-requests') {
          return { reminderType: 'REMINDER_TO_TEAM_ADMIN_ABOUT_PENDING_JOIN_REQUESTS', teamId: member.teamId };
        }
        break;
      default:
        break;
    }
  }

  return (
    <Table size="small" stickyHeader={true}>
      <TableHead >
        <StyledTableRow >
          <StyledTableGradientHead align="left" colSpan={5}>
            <div className='container-space-between'>
              <span>{title?.toUpperCase()}</span>
              {(title?.toUpperCase() === 'TEAMS' || title?.toUpperCase() === 'MEMBERS' || title?.toUpperCase() === 'PLAYERS' || title?.toUpperCase() === 'PATIENTS' || title?.toUpperCase() === 'TEAM ADMINISTRATORS' || title?.toUpperCase() === 'LEAGUE ADMINISTRATOR') &&
                <div>
                  <a onClick={(event) => onAdd(event, title)}>+ Add New</a>
                  {(title?.toUpperCase() != 'TEAMS') && (
                    <a onClick={(event) => onAdd(event, title, true)} style={{marginLeft: 30}}>+ Add Existing</a>
                  )}
                  {((title?.toUpperCase() == 'PLAYERS' || title?.toUpperCase() == 'PATIENTS') && rows?.length != 0) && (
                    <a onClick={(event) => onArchive(event)} style={{marginLeft: 30}}>Archive Roster</a>
                  )}
                </div>
              }
            </div>
          </StyledTableGradientHead>
        </StyledTableRow>
        <StyledTableRow>
          {headList.map((head, index) => <StyledTableCell className={head.toLowerCase()} key={index}><span className={`table-header-text table-header-text-${head.toLowerCase()}`}>{head.toUpperCase()}</span></StyledTableCell>)}
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {rows.length !== 0 && rows.map((object, index) => {
          return (
            !isLoading ? <StyledTableRow key={index}>
              <StyledTableCell align='left'>{object?.name ? object.name : object?.team ? object.team : `${object.firstName} ${object.lastName}`}</StyledTableCell>
              {headList.length === 3 && (headList.includes('Baseline') ?
                <StyledTableCell align={width >= 900 ? 'left' : 'center'}>
                  <span className={`fa fa-circle ${object?.benched === 1 ? 'basline-icon-orange' : object?.lastBaseline === null ? 'basline-icon-red' : 'basline-icon-green'}`} />
                  {width >= 900 && (object?.benched === 1 ? 'Recovery Test Required' : object.lastBaseline  === null ? 'Baseline Needed' : object?.lastBaseline)}
                </StyledTableCell>
                : <StyledTableCell align='left'>
                  <span>{object?.count ? object.count : 0}</span>
                </StyledTableCell>
              )}
              <StyledTableCell>
                <div className='align-space-around'>
                  {width >= 900 ? actions.map((action, index) =>
                    //  action.toLowerCase() !== 'change role' ?
                    <TableButton key={index} title={action} hoverColor='#003F60' handleClick={(event) => onAction(event, action.toLowerCase(), getParam(action.toLowerCase(), object))} />
                    // :
                    // <TableDropdown actions={actions} handleChange={(event) => onAction(event, action.toLowerCase(), getParam(action.toLowerCase(), object))} />
                  ) :
                    <TableDropdown actions={actions} handleChange={(value) => onAction(null, value.toLowerCase(), getParam(value.toLowerCase(), object))} />
                  }
                </div>
              </StyledTableCell>
            </StyledTableRow>
              :
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <Skeleton variant="text" animation="wave" />
                </StyledTableCell>
              </StyledTableRow>
          )
        })}
      </TableBody>
    </Table>
  );
}

export default GradientTable;
