import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {useAuth} from './useAuth';
import { useLocalStorage } from "./useLocalStorage";
import Constants from "../constant/Constants";

import { postRequest } from "../helper/axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import URLConstant from "../constant/URLConstant";

const UserContext = React.createContext()

function UserProvider(props) {

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const { user, coreData } = useAuth();
  const [ leagues, setLeaguesInfo ] = useLocalStorage(Constants.ASYNC_STORAGE_KEYS.LEAGUE_DATA, null);
  const [ teams, setTeamsInfo ] = useLocalStorage(Constants.ASYNC_STORAGE_KEYS.TEAMS_DATA, null);

  const updateUserLeagues = (leagues) => {
      setLeaguesInfo(leagues);
  };

  const updateUserTeams = (teams) => {
      setTeamsInfo(teams);
  };

  const loadDashboardInfo = async (params) => {

    try {

        var inputParams = {
          userID: params?.id || user?.id,
          userCode: params?.userCode || user?.userCode,
        };
      
        if (!inputParams?.userID && !inputParams?.userCode) {
          return;
        };

        const response = await postRequest(axiosPrivate, URLConstant.DASHBOARD_INFO, inputParams);
        const data = response?.data;
      
        if (data?.message) {
          alert(data.message);      
        };

        if (data?.status && data?.data) {

          var responseData = data?.data;

          updateUserLeagues(responseData?.leagues);
          updateUserTeams(responseData?.teams);
          return;
        };

    } catch (err) {
        console.log(err)
        return
    };
  };

  const resetUserData = () => {
    updateUserLeagues(null);
    updateUserTeams(null);
  };

  const value = useMemo(
    () => ({
        userInfo: user,
        teams,
        leagues,
        updateUserLeagues,
        updateUserTeams,
        loadDashboardInfo,
        resetUserData
    }),
    [user, leagues?.length, teams]
  )

  return <UserContext.Provider value={value} {...props} />
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export {UserProvider, useUser}