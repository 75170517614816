import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const StyledSelect = styled(Select, {
  name: "StyledSelect"
})({
  "& .MuiInputBase-input": {
    border: '1px solid #003F60',
    borderRadius: '4px',
    padding: '5px 10px',
    color: '#0C243F',
    fontSize: '16px',
    fontFamily:'Montserrat',
  },

});

const UserRoleDropdown = (props) => {

    const [roles, setRoles] = useState(props.roles);
    const [defaultValue, setDefaultValue] = useState(props.value);

    useEffect(() => {
        
        if (!roles?.length && props?.roles) setRoles(props.roles)

        var selectedValue = props?.value?.toLowerCase() == 'patient'? 'player' : props?.value?.toLowerCase();

        if (props?.roles?.length) {
            var selectedRole = (props.roles?.find((role) => role.slug === selectedValue))
            if (selectedRole?.slug) {
                setDefaultValue(selectedRole.slug)
            };
        };

    }, [props.roles, props.value]);


    const handleOnChange = (event) => {
        props.onChange(event);
    }

    return (
        <>
            <StyledSelect
                // labelId="role-label"
                // id="role"
                onChange={handleOnChange}
                defaultValue={defaultValue}
                value={defaultValue}
                fullWidth
                // label="role"
                variant="outlined"
            >
                {
                    roles && roles?.length ?
                        roles.map((role, index) => <MenuItem value={role.slug} key={index}>{role.title}</MenuItem>)
                        :
                        <MenuItem disabled value="">
                            <em>None</em>
                        </MenuItem>   
                }
            </StyledSelect>
        </>
    );
}

export default UserRoleDropdown;