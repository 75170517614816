import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

// const Button = styled(
//   MuiButton,
// )(({ theme, rounded }) => ({
//   borderRadius: rounded ? '20px' : null,
//   border: '1px solid #003F60',
//   alignItems:'center',
//   color:'#003F60',
// }));

const BorderButtonClickable = (props) => {
  const { size = 'large', title = 'Submit', isLoading = false, handleClick } = props;
  return (
    <Button
      sx={{
        width: size === 'small' ? 175 : 160,
        fontSize: size === 'small' ? '12px' : '14px',
        lineHeight: size === 'small' ? '13px' : '15px',
        fontFamily:'Montserrat',
        fontWeight:'600',
        color: size === 'small' ? '#003F60' : '#FFFFFF',
        border: `1px solid ${size === 'small' ? '#003F60' : '#FFFFFF'}`,
        borderRadius: size === 'small' ? 25 : '68px',
        alignItems: 'center',
        marginBottom: size === 'small' && '7px',
        padding:'4px 5px',
        "&:hover": {
          color: size === 'small' ? '#FFFFFF' : '#003F60',
          backgroundColor: size === 'small' ? '#003F60' : '#FFFFFF',
          border: `1px solid ${size === 'small' ? '#FFFFFF' : '#003F60'}`,
        }
      }}
      variant="outlined"
      size={size}
      onClick={handleClick}
    >
      {title}
    </Button>
  )
}

export default BorderButtonClickable;