import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import { postForm } from "../../helper/axios";
import { downLoadFile } from "../../helper/util";
import URLConstant from "../../constant/URLConstant";

import EditIcon from '../../../src/assets/icons/edit-icon.svg';
import TestPercentileCard from "../cards/TestPercentileCard";
import BorderButtonClickable from "../buttons/BorderButtonClickable";
import PIEChart from "../chart/PIEChart";

var momentTZ = require('moment-timezone');

const TeamWidget = (props) => {

  const { teamInfo, joinCode = 'CODE NAME', playerRoleAlias = '', selectedWidgetSlug = '' } = props;
  const { leagueId = '', teamId = '' } = useParams();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [widgetInfo, setWidgetInfo] = useState(props?.widgetInfo);
  const [pieChartStats, setPieChartStats] = useState();
  const [PieTitle, setPieTitle] = useState('');
  const [selectedSlug, setSelectedSlug] = useState('');

  useEffect(() => {
    console.log('props:::', props);
    loadInitialWidget();
  }, [props?.widgetInfo?.timestamp]);

  useEffect(() => {
    if (selectedWidgetSlug) {
      /* var widget = props?.widgetInfo?.widgets?.find((item) => selectedWidgetSlug === item?.slug);
      if (widget?.PIEChartData) {
        setPieChartStats(widget.PIEChartData);
        setPieTitle(widget.title);
        setSelectedSlug(selectedWidgetSlug)
      }; */
      setSelectedSlug(selectedWidgetSlug);
    }
    else loadInitialWidget()
  }, [selectedWidgetSlug]);

  const loadInitialWidget = async () => {

    var widget = props?.widgetInfo?.widgets?.[0];

    if (widget?.PIEChartData) {
      setWidgetInfo(props?.widgetInfo);
      setPieChartStats(widget.PIEChartData);
      setPieTitle(widget.title)
      setSelectedSlug('')
    };

  }

  const handleRosterTemplateDownload = async () => {
    try {
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      if (props.setLoading) props.setLoading(true);

      const response = await postRequest(axiosPrivate, URLConstant.DOWNLOAD_ROASTER_TEMPLATE, inputParams);

      const data = response?.data;

      if (props.setLoading) props.setLoading(false);

      if (data) {
        if (window.confirm('Please confirm to start the download.')) {
          await downLoadFile(data, 'ROSTER_TEMPLATE.csv');
        }
        return
      }
      else {
        if (data?.message) alert(data.message);
        return;
      };
    } catch (err) {
      console.log(err)
    }
  }

  const handleExportTestResults = async () => {
    try {
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      if (props.setLoading) props.setLoading(true);

      const response = await postRequest(axiosPrivate, URLConstant.DOWNLOAD_TEAM_TEST_RESULTS, inputParams);

      const data = response?.data;

      if (props.setLoading) props.setLoading(false);

      if (data) {
        if (data?.message) alert(data.message);
        return
      }
      else {
        return;
      };
    } catch (err) {
      console.log(err)
    }
  }

  const handleRosterDownload = async () => {
    try {
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        timezone: momentTZ.tz.guess(),
      };

      if (props.setLoading) props.setLoading(true);

      const response = await postRequest(axiosPrivate, URLConstant.DOWNLOAD_TEAM_ROASTER, inputParams);

      const data = response?.data;

      if (props.setLoading) props.setLoading(false);

      if (data) {
        alert('Your download will start in a few seconds...');
        await downLoadFile(data, 'TEAM_ROSTER.csv');
        return
      }
      else {
        if (data?.message) alert(data.message);
        return;
      };
    } catch (err) {
      console.log(err)
    }
  }

  const handleClick = (event, buttonType) => {
    event.preventDefault();
    switch (buttonType) {
      case 'UPLOAD ROSTER': navigate(`/league/${leagueId}/team/${teamId}/upload-roster`);
        break;
      case 'ADD PLAYER': navigate(`/league/${leagueId}/team/${teamId}/create-team-member/?role=${playerRoleAlias}`);
        break;
      case 'EXPORT ROSTER': handleRosterDownload();
        break;
      case 'ROSTER ARCHIVES': navigate(`/league/${leagueId}/team/${teamId}/archives`);
        break;
      case 'DOWNLOAD ROSTER TEMPLATE': handleRosterTemplateDownload();
        break;
      case 'EXPORT TEST RESULTS': handleExportTestResults();
        break;
    }
  }

  return (
    <Container className="widget-container">
      <Row className="widget-row">
        <Col className="team-widget-col full-width-container">
          <PIEChart pieData={pieChartStats} title={PieTitle} flexColumn={true} leagueId={leagueId} teamId={teamId} />
        </Col>
        <Col className="team-widget-col" md={{ order: 'last' }} sm={{ order: 'last' }} xs={{ order: 'last' }}        >
          {widgetInfo?.widgets.map((item) => {
            if (item.hide) return;
            return <TestPercentileCard
              title={item.title}
              value={item.value}
              slug={item.slug}
              selectedSlug={selectedSlug}
              handleClick={(event) => {
                props.onAction(event, item.slug)
              }} />
          })}
        </Col>
        <Col className="team-widget-col" lg={{ order: 'last' }} xl={{ order: 'last' }} xxl={{ order: 'last' }}>
          <div className="roster-card-Container">
            <span className="widget-join-code">TEAM JOIN CODE :</span>
            <div className="widget-code-name-container">
              <span className="widget-code-name">{joinCode}</span>
              <a href="join-code/"><img className="edit-icon" src={EditIcon} alt="Edit" /></a>
            </div>
            {teamInfo?.permissions?.rosterUpload &&
              <BorderButtonClickable title='UPLOAD ROSTER' size="small" handleClick={(event) => handleClick(event, 'UPLOAD ROSTER')} />
            }
            <BorderButtonClickable title={`+ ADD ${playerRoleAlias.toUpperCase()} `} size="small" handleClick={(event) => handleClick(event, 'ADD PLAYER')} />
            {teamInfo?.permissions?.rosterUpload &&
              <BorderButtonClickable title='EXPORT ROSTER' size="small" handleClick={(event) => handleClick(event, 'EXPORT ROSTER')} />
            }
            <BorderButtonClickable title='ROSTER ARCHIVES' size="small" handleClick={(event) => handleClick(event, 'ROSTER ARCHIVES')} />
            {/* {teamInfo?.permissions?.rosterUpload &&
              <BorderButtonClickable title='DOWNLOAD TEMPLATE' size="small" handleClick={(event) => handleClick(event, 'DOWNLOAD ROSTER TEMPLATE')} />
            } */}
            <BorderButtonClickable title='EXPORT TEST RESULTS' size="small" handleClick={(event) => handleClick(event, 'EXPORT TEST RESULTS')} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TeamWidget;