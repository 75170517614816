import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import Skeleton from '@mui/material/Skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

/* Internal */

import { AuthProvider } from "../hooks/useAuth";
import { UserProvider } from "../hooks/useUser";

/* PAGES */

/* Checkout and ProcessOrder */

import Checkout from "../pages/payments/Checkout";
import CustomizePlan from "../pages/payments/CustomizePlan";
import Purchase from "../pages/payments/Purchase";
import ProcessOrder from "../pages/payments/ProcessOrder";
import CreateTeamMember from "../pages/team/CreateTeamMember";
import CreateTeam from "../pages/team/CreateTeam";
import EditTeam from "../pages/team/EditTeam";
import RosterArchives from "../pages/team/RosterArchives";
import TeamDashboard from "../pages/team/TeamDashboard";
import TeamLayout from "../components/layouts/TeamLayout";
import MemberProfile from "../pages/team/MemberProfile";
import UpdateTeamJoinCode from "../pages/team/UpdateTeamJoinCode";
import LeagueLayout from "../components/layouts/LeagueLayout";
import DeepLinkingFallback from "../pages/DeepLinkingFallback";
import CreateLeagueAdmin from "../pages/league/CreateLeagueAdmin";
import LeagueAdmins from "../pages/league/LeagueAdmins";
import LeagueConfiguration from "../pages/league/LeagueConfiguration";
import ListMembersForApproval from "../pages/team/ListMembersForApproval";
import UploadRoster from "../pages/team/UploadRoster";
import BaselineTestsNeeded from "../pages/team/BaselineTestsNeeded";
import PostInjuryTaken from "../pages/team/PostInjuryTaken";
import BenchedMembers from "../pages/team/BenchedMembers";
import GradientLayout from '../components/layouts/GradientLayout';
import GradientPublicLayout from '../components/layouts/GradientPublicLayout';
import TierPricing from '../pages/tier-pricing/TierPricing';

/* Public pages */
const Login = React.lazy(async () => await import("../pages/auth/Login"));
const SignUp = React.lazy(async () => await import("../pages/auth/SignUp"));
const Crashcourse = React.lazy(async () => await import("../pages/crashcourse/Index"));
const ResetPassword = React.lazy(async () => await import("../pages/auth/ResetPassword"));
const ForgotPassword = React.lazy(async () => await import("../pages/auth/ForgotPassword"));

const VerifyEmail = React.lazy(async () => await import("../pages/common/VerifyEmail"));
const VerifyPhone = React.lazy(async () => await import("../pages/common/VerifyPhone"));
const FileNotFound = React.lazy(async () => await import("../pages/common/FileNotFound"));

/* User Specific */
const Profile = React.lazy(async () => await import("../pages/user/Profile"));
const Dashboard = React.lazy(async () => await import("../pages/user/Dashboard"));
const EditProfile = React.lazy(async () => await import("../pages/user/EditProfile"));
const ChangeEmail = React.lazy(async () => await import("../pages/user/ChangeEmail"));
const ChangePhone = React.lazy(async () => await import("../pages/user/ChangePhone"));
const ChangeAvatar = React.lazy(async () => await import("../pages/user/ChangeAvatar"));
const ChangePassword = React.lazy(async () => await import("../pages/user/ChangePassword"));
const VerifyNewEmail = React.lazy(async () => await import("../pages/user/VerifyNewEmail"));

/* SubAccounts */
const AddAthlete = React.lazy(async () => await import("../pages/sub-accounts/AddAthlete"));
const AthleteProfile = React.lazy(async () => await import("../pages/sub-accounts/AthleteProfile"));
const EditAthleteProfile = React.lazy(async () => await import("../pages/sub-accounts/EditAthleteProfile"));
const ChangeAthletePassword = React.lazy(async () => await import("../pages/sub-accounts/ChangeAthletePassword"));
const ChangeAthleteAvatar = React.lazy(async () => await import("../pages/sub-accounts/ChangeAthleteAvatar"));
const AthleteTestResults = React.lazy(async () => await import("../pages/sub-accounts/AthleteTestResults"));

/** Super Admin */
const SuperAdminDashboard = React.lazy(async () => await import("../pages/super-admin/Dashboard"));
const SuperAdminUserDashboard = React.lazy(async () => await import("../pages/super-admin/users/Dashboard"));
const SuperAdminAddUser = React.lazy(async () => await import("../pages/super-admin/users/AddUser"));
const SuperAdminEditUser = React.lazy(async () => await import("../pages/super-admin/users/EditUser"));
const SuperAdminViewUser = React.lazy(async () => await import("../pages/super-admin/users/ViewUser"));
const SuperAdminLeaguesDashboard = React.lazy(async () => await import("../pages/super-admin/leagues/Dashboard"));
const SuperAdminAddLeague = React.lazy(async () => await import("../pages/super-admin/leagues/AddLeague"));
const SuperAdminEditLeague = React.lazy(async () => await import("../pages/super-admin/leagues/EditLeague"));
const SuperAdminViewLeague = React.lazy(async () => await import("../pages/super-admin/leagues/ViewLeague"));
const SuperAdminEditTeam = React.lazy(async () => await import("../pages/super-admin/leagues/EditTeam"));
const SuperAdminReportDashboard = React.lazy(async () => await import("../pages/super-admin/reports/Dashboard"));
const FailedTestAttempts = React.lazy(async () => await import("../pages/super-admin/reports/FailedTestAttempts"));

/* Team */
const JoinTeamWithCode = React.lazy(async () => await import("../pages/team/JoinTeamWithCode"));

/* League Dashboard */
const LeagueDashboard = React.lazy(async () => await import("../pages/league/LeagueDashboard"));
const Teams = React.lazy(async () => await import("../pages/league/Teams"));

/* Test Results */
const TestResults = React.lazy(async () => await import("../pages/test/TestResults"));

/* Layouts */
const NavBar = React.lazy(async () => await import("../components/nav/NavBar"));
const Footer = React.lazy(async () => await import("../components/nav/Footer"));
const CommonLayout = React.lazy(async () => await import("../components/layouts/CommonLayout"));
const PublicLayout = React.lazy(async () => await import("../components/layouts/PublicLayout"));
const ProtectedLayout = React.lazy(async () => await import("../components/layouts/ProtectedLayout"));
const ProfileLayout = React.lazy(async () => await import("../components/layouts/ProfileLayout"));
const SuperAdminProfileLayout = React.lazy(async () => await import("../components/layouts/SuperAdminProfileLayout"));

export default function App() {
  return (
    <Suspense fallback={
      <> <Skeleton animation="wave" height={10} /> </>
    }>
      <AuthProvider>
        <UserProvider>
          <NavBar />
          <Routes>
            <Route element={<CommonLayout />}>
              <Route element={<GradientPublicLayout />}>
                <Route path="/" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/subscription-plans" element={<TierPricing />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />}>
                  <Route path=":user_id/:user_code/:resetCode" element={<ResetPassword />} />
                </Route>
                <Route path="/checkout/*" element={<Checkout />} />
                <Route path="/customize-plan/*" element={<CustomizePlan />} />
                <Route path="/purchase/*" element={<Purchase />} />
                <Route path="/process-order/*" element={<ProcessOrder />} />
              </Route>

              <Route path="/crashcourse/*" element={<Crashcourse />} />
              
              <Route element={<GradientLayout />}>
                <Route path="/verify-email" element={<VerifyEmail />}>
                  <Route path=":user_id/:user_code/:token" element={<VerifyEmail />} />
                  <Route path=":user_id/:user_code/:token/*" element={<VerifyEmail />} />
                </Route>
                <Route path="/verify-phone" element={<VerifyPhone />}>
                  <Route path=":user_id/:user_code/:token" element={<VerifyPhone />} />
                  <Route path=":user_id/:user_code/:token/*" element={<VerifyPhone />} />
                </Route>
                <Route path="/link/*" element={<DeepLinkingFallback />} />
              </Route>

              <Route path="/super-admin" element={<ProtectedLayout />}>
                <Route path="dashboard" element={<SuperAdminDashboard />} />
                <Route path="users" element={<SuperAdminUserDashboard />} />
                <Route path="users/add" element={<SuperAdminAddUser />} />
                <Route path="users/edit/:userID" element={<SuperAdminEditUser />} />
                <Route path="users/view/:userID" element={<SuperAdminViewUser />} />
                <Route path="" element={<SuperAdminProfileLayout />}>
                  <Route path="users/test-results/:userID" element={<TestResults />} />
                </Route>
                <Route path="leagues" element={<SuperAdminLeaguesDashboard />} />
                <Route path="leagues/add" element={<SuperAdminAddLeague />} />
                <Route path="league/edit/:leagueId" element={<SuperAdminEditLeague />} />
                <Route path="league/view/:leagueId" element={<SuperAdminViewLeague />} />
                <Route path="league/:leagueId/team/:teamId/edit" element={<SuperAdminEditTeam />} />
                <Route path="reports" element={<SuperAdminReportDashboard />} />
                <Route path="failed-test-attempts" element={<FailedTestAttempts />} />
              </Route>

              <Route path="/user" element={<ProtectedLayout />}>
                <Route path="" element={<ProfileLayout />} >
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="profile/:userID" element={<Profile />} />
                  <Route path="edit-profile" element={<EditProfile />} />
                  <Route path="change-password" element={<ChangePassword />} />
                  <Route path="change-email" element={<ChangeEmail />} />
                  <Route path="change-phone" element={<ChangePhone />} />
                  <Route path="change-avatar" element={<ChangeAvatar />} />
                  <Route path="order-summary" element={<Checkout />} />
                  <Route path="verify-new-email" element={<VerifyNewEmail />} >
                    <Route path=":user_id/:user_code/:token" element={<VerifyEmail />} />
                    <Route path=":user_id/:user_code/:token/*" element={<VerifyEmail />} />
                    <Route path="*" element={<VerifyEmail />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/athlete" element={<ProtectedLayout />}>
                <Route path="" element={<ProfileLayout />} >
                  <Route path="add" element={<AddAthlete />} />
                </Route>
                <Route path=":userID/profile" element={<AthleteProfile />} />
                <Route path=":userID/edit-profile" element={<EditAthleteProfile />} />
                <Route path=":userID/test/results" element={<AthleteTestResults />} />
                <Route path=":userID/change-password" element={<ChangeAthletePassword />} />
                <Route path=":userID/change-avatar" element={<ChangeAthleteAvatar />} />
              </Route>

              <Route path="/test/" element={<ProtectedLayout />}>
                <Route path="" element={<ProfileLayout />} >
                  <Route path="results/:userID" element={<TestResults />} />
                  <Route path="results" element={<TestResults />} />
                </Route>
              </Route>

              <Route path="/league/:leagueId" element={<ProtectedLayout hideOuterContainer={true} />} >
                <Route path="" element={< LeagueDashboard />} />
                <Route path="dashboard" element={< LeagueDashboard />} />
                <Route path="/league/:leagueId/team/:teamId/" element={<TeamDashboard />} />
                <Route path="/league/:leagueId/team/:teamId/" element={<TeamLayout />} >
                  <Route path="member/:memberId/user/:userID/test/results" element={<TestResults />} />
                </Route>
              </Route>

              <Route path="" element={<ProtectedLayout hideOuterContainer={true} />} >

                <Route path="/league/:leagueId/" element={<LeagueLayout />}>

                  {/* <Route path="league-admins" element={<LeagueAdmins />} /> */}
                  {/* <Route path="configuration" element={<LeagueConfiguration />} /> */}
                  {/* <Route path="teams" element={<Teams />} /> */}
                </Route>
                <Route path="/league/:leagueId/" element={<GradientLayout />}>
                  <Route path="create-league-admin" element={<CreateLeagueAdmin />} />
                  <Route path="create-team" element={<CreateTeam />} />
                </Route>

                <Route path="/league/:leagueId/team/:teamId/" element={<TeamLayout />} >
                  <Route path="upload-roster" element={<UploadRoster />} />
                  <Route path="team-members-awaiting-for-approvals" element={<ListMembersForApproval />} />
                  <Route path="create-team-member" element={<CreateTeamMember />} />
                  <Route path="join-code/" element={<UpdateTeamJoinCode />} />
                  <Route path="member/:memberId/profile/:userID" element={<MemberProfile />} />
                  <Route path="edit-team" element={<EditTeam />} />
                  <Route path="archives" element={<RosterArchives />} />
                  {/* <Route path="baseline-tests-needed" element={<BaselineTestsNeeded />} /> */}
                  {/* <Route path="players-benched" element={<BenchedMembers />} /> */}
                  {/* <Route path="post-injury-tests-completed" element={<PostInjuryTaken />} /> */}
                </Route>

                <Route path="team-join-code" element={<JoinTeamWithCode />} />
              </Route>

              <Route path="/*" element={<FileNotFound />} />

            </Route>

          </Routes>
        </UserProvider>
        <Footer />
      </AuthProvider >
    </Suspense >
  );
};