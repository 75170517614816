import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const TableButton = (props) => {
  const { title = 'Submit', hoverColor = '#FFFFFF', isLoading = false, handleClick } = props;
  return (
    <LoadingButton
      sx={{
        minWidth: '100px',
        maxHeight: '23px',
        margin: '0 10px',
        borderRadius: '20px',
        border: '1px solid #003F60',
        color: '#003F60',
        fontWeight: '500',
        fontSize: '11px',
        fontFamily: 'Montserrat',
        textAlign: 'center',
        "&:hover": {
          color: '#FFFFFF',
          backgroundColor: hoverColor,
          border: `1px solid #FFFFFF`,
        }
      }}
      variant="outlined"
      size='small'
      onClick={handleClick}
      loading={isLoading}
      rounded>
      {title}
    </LoadingButton>
  )
}

export default TableButton;
