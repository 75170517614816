import React, { Suspense, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { currencyFormatter } from '../../helper/util';
import CONSTANTS from "../../constant/Constants";
import FormLayout from '../../components/layouts/FormLayout';
import Checkbox from '@mui/material/Checkbox';

import axiosClient, { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";
import FormButton from '../../components/buttons/FormButton';

const Checkout = () => {

  const navigate = useNavigate();
  const queryString = window.location.search;
  const subscriptionTerm = '1 Year';
  
  const [ isLoading, setLoading ] = useState(true);
  const [ totalPrice, setTotalPrice ] = useState('...');
  const [ subscriptionData, setSubscriptionData ] = useLocalStorage(CONSTANTS.ASYNC_STORAGE_KEYS.USER_SUBSCRIPTION_DATA, null);
  const { name, orgType } = subscriptionData?.league || {};
  const { firstName = '', lastName = '', email = '' } = subscriptionData?.user || {};
  const { plan, headCount } = subscriptionData?.subscription || {};
  const [ acceptTermsAndCondition, setAcceptTermsAndCondition ] = useState(true);

  useEffect(() => {

    try {

      if (subscriptionData?.url && subscriptionData?.user && subscriptionData?.subscription) {
        getPackagePrice();
      }
      else {
        handleFailure();
      };
    }
    catch (error) {
      console.log('Data not available!', error);
      handleFailure();
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!acceptTermsAndCondition) return alert("Accept Subscription policy agreement and continue.");
    if (subscriptionData?.url)
      window.location = subscriptionData?.url || '#'
  };

  const handleFailure = () => {
    navigate("/sign-up" + queryString, true);
  };

  const getPackagePrice = async () => {

    var GET_PACKAGE_PRICE_API_URL = URLConstant.GET_PACKAGE_PRICE + queryString;
    var queryParams = Object.fromEntries([...(new URLSearchParams(window.location.search))]);

    const inputParams = {
      subscriptionPlanId: queryParams?.['subscription-plan-id'],
      headCount: queryParams?.['head-count']
    };

    const response = await postRequest(axiosClient, GET_PACKAGE_PRICE_API_URL, inputParams);
    const data = response?.data;

    if (data?.data?.totalPrice) {
      var computedPrice = currencyFormatter(data?.data?.totalPrice);
      setTotalPrice(computedPrice);
      setLoading(false);
    }
    else {
      handleFailure();
    };
  };

  return (
    <FormLayout title="Order Summary">
      <Suspense fallback={<Skeleton count={5} />}>
        <Row className="mt-5 justify-content-md-center">
          <Col lg={8} className="py-4 ps-5 order-summary-container">
              {firstName && lastName &&
                <Stack direction="horizontal" gap={3}>
                  <span className='profile-text-label-white'>Name :</span>
                  <span className='profile-text'>{firstName} {lastName}</span>
                </Stack>
              }
              {email &&
                <Stack direction="horizontal" gap={3} className="mt-2">
                  <span className='profile-text-label-white'>Email :</span>
                  <span className='profile-text'>{email}</span>
                </Stack>
              }
              {subscriptionData?.league && name &&
                <Stack direction="horizontal" gap={3} className="mt-2">
                  <span className='profile-text-label-white'>League :</span>
                  <span className='profile-text'>{name}</span>
                </Stack>
              }
              {plan &&
                <Stack direction="horizontal" gap={3} className="mt-2">
                  <span className='profile-text-label-white'>Plan :</span>
                  <span className='profile-text'>{plan === '1' ? 'INDIVIDUAL' : plan === '2' ? 'BASIC' : plan === '3' ? 'PRO' : plan === '4' ? 'PREMIER' : 'NA'}</span>
                </Stack>
              }
              {headCount &&
                <Stack direction="horizontal" gap={3} className="mt-2">
                  <span className='profile-text-label-white'># of Athletes :</span>
                  <span className='profile-text'>{headCount}</span>
                </Stack>
              }
              {subscriptionTerm &&
                <Stack direction="horizontal" gap={3} className="mt-2">
                  <span className='profile-text-label-white'>Subscription Term :</span>
                  <span className='profile-text'>{subscriptionTerm}</span>
                </Stack>
              }
              {totalPrice &&
                <Stack direction="horizontal" gap={3} className="mt-2">
                  <span className='profile-text-label-white'>Total :</span>
                  <span className='profile-text'>{totalPrice}</span>
                </Stack>
              }

          </Col>
          <Col lg={8}>
            <Stack direction="vertical" gap={3} className="mx-auto mt-5 mb-5">
              <label className={acceptTermsAndCondition ? "text-center" : "text-center label-error"}>
                <Checkbox name={"termsNdCondition"} defaultChecked color="success" onChange={() => setAcceptTermsAndCondition(!acceptTermsAndCondition)} />
                <span className="hitcheck-link-text">I accept <a href="https://www.hitcheck.com/privacy-policy" className="hitcheck-link-text-blue" target="_blank">Subscription Policy Agreement</a></span>
              </label>

              <FormButton isLoading={isLoading} handleSubmit={handleSubmit} title=" Continue" skipCheckIcon />
            </Stack>
          </Col>
        </Row>
      </Suspense>
    </FormLayout>
  )
}

export default Checkout;