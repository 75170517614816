const URLS = {

    /* Onboarding, Auth API Routes */
    LOGIN: 'users/login',
    LOGOUT: 'users/logout',
    FORGET_PASSWORD: 'users/forgot-password',
    RESET_PASSWORD: 'users/reset-password',
    SOLO_SIGNUP: 'subscriptions/solo-subscription/',
    VERIFY_EMAIL: 'users/verify-email',
    VERIFY_PHONE: 'users/verify-phone',

    /* User Specific API Routes*/
    CHANGE_EMAIL: 'users/change-email',
    CHANGE_PHONE: 'users/change-phone',
    CHANGE_PASSWORD: 'users/change-password',
    EDIT_PROFILE: 'users/edit-profile',
    MY_PROFILE: 'users/my-profile',
    DASHBOARD_INFO: 'dashboard/get-dashboard-info',
    UPDATE_EMAIL: 'users/update-email',
    UPDATE_PHONE: 'users/update-phone',
    UPLOAD_AVATAR: 'users/upload-avatar',
    TEST_RESULTS: 'test/results',
    SEND_VERIFICATION_REQUEST: 'users/send-verification-request',

    /* Miscellaneous */
    SUBMIT_CRASHCOURSE_QUIZ_RESPONSE: 'crashcourse/submit-response',

    /* Super admin */
    SUPER_ADMIN_DASHBOARD: 'super-admin/dashboard',
    USER_PROFILE: 'super-admin/user-profile',
    GET_USERS: 'super-admin/get-users',
    ADD_USER: 'super-admin/add-user',
    EDIT_USER: 'super-admin/edit-user',
    DELETE_USER: 'super-admin/delete-user',
    SEARCH_USERS: 'super-admin/search-users',
    GET_LEAGUES: 'super-admin/get-leagues',
    GET_LEAGUE_INFO: 'super-admin/get-league-info',
    ADD_LEAGUE: 'super-admin/add-league',
    EDIT_LEAGUE: 'super-admin/edit-league',
    GET_TEAM_INFO: 'super-admin/get-team-info',
    EDIT_TEAM_SETTINGS: 'super-admin/edit-team',
    GENERATE_TEST_REPORT: 'super-admin/generate-test-report',
    GET_FAILED_TEST_ATTEMPTS: 'super-admin/failed-test-attempts',

    /* Payments, Subscription and Order Processing routes */
    GET_SUBSCRIPTION_PLANS: 'subscription-plans/get-subscription-plans',
    CREATE_CHECKOUT_SESSION: 'subscriptions/create-checkout-session',
    GET_PACKAGE_PRICE: 'subscription-plans/get-package-price',
    CREATE_SUBSCRIPTION: 'subscriptions/create-subscription',
    HANDLE_SUBSCRIPTION: 'subscriptions/handle-subscription',
    COMPLETE_LEAGUE_SUBSCRIPTION: 'subscriptions/complete-league-subscription',

    /* League routes */
    GET_TEAMS:'leagues/get-teams',
    LEAGUE_INFOS: 'leagues/get-league-info',
    CREATE_LEAGUE_ADMIN:'leagues/create-league-member',
    GET_LEAGUE_ADMINS:'leagues/get-league-members',
    REMOVE_LEAGUE_ADMIN:'leagues/remove-league-member',
    LEAGUE_CONFIG:'',
    TEAM_ATHLETES_COUNT:'leagues/get-teams-athletes-counts',
    POST_INJURY_COMPLETED:'leagues/get-teams-post-injury-completed-counts',
    BASELINE_NEEDED_COUNTS:'leagues/get-teams-base-line-needed-count',
    TEAMS_BENCHED_COUNTS:'leagues/get-teams-benched-counts',
    CURRENT_BENCHED_COUNTS:'leagues/get-teams-current-benched-counts',
    PENDING_JOIN_REQUESTS_COUNT: 'leagues/get-teams-pending-join-requests-counts',
    RESET_LEAGUE_TEST_DATA: 'leagues/reset-test-data',

    /* Team routes */
    CREATE_TEAM:'teams/create-team',
    EDIT_TEAM:'teams/edit-team',
    GET_TEAM:'teams/get-team',
    GET_TEAM_USER_ROLES:'teams/get-team-user-roles',
    UPDATE_TEAM_JOIN_CODE: 'teams/update-team-join-code',
    RESET_TEAM_TEST_DATA: 'teams/reset-test-data',
    JOIN_TEAM_WITH_CODE:'team-members/join-team-with-code',
    CREATE_TEAM_MEMBER:'team-members/create-member',
    GET_MEMBERS:'team-members/get-members',
    GET_MEMBERS_FOR_APPROVAL:'team-members/get-members-for-approval',
    APPROVE_MEMBER:'team-members/approve-member',
    REMOVE_MEMBER: 'team-members/remove-member',
    REMOVE_MYSELF: 'team-members/remove-myself',
    ARCHIVE_ROSTER: 'team-members/archive-roster',
    GET_ARCHIVED_MEMBERS: 'team-members/get-archived-members',
    RESTORE_ARCHIVED_MEMBER: 'team-members/restore-archived-member',
    MEMBER_TEST_RESULTS: 'team-members/test-results',
    DOWNLOAD_ROASTER_TEMPLATE:'team-members/download-roster-template',
    DOWNLOAD_TEAM_ROASTER:'team-members/download-team-roster',
    UPLOAD_ROASTER:'team-members/upload-team-roster',
    CHANGE_ROLE:'team-members/change-role',
    MEMBER_PROFILE:'team-members/member-profile',
    GET_ROLES:'user-roles/get-roles',
    BASELINE_TESTS_COMPLETED:'teams/get-baseline-test-completed-members',
    POST_INJURY_TESTS_COMPLETED:'teams/get-post-injury-test-completed-members',
    BENCHED_MEMBERS:'teams/get-current-benched-members',
    TEAM_SEND_REMINDER : 'teams/send-reminder',
    LEAGUE_SEND_REMINDER : 'leagues/send-reminder',
    DOWNLOAD_TEAM_TEST_RESULTS: 'test/download-team-test-results',
    
    /* Parent-Subaccounts */
    PARENT_DASHBOARD: 'sub-accounts/dashboard-info',
    ATHLETE_PROFILE: 'sub-accounts/athlete-profile',
    ADD_ATHLETE: 'sub-accounts/add-subaccount'
};

export default URLS;