import React, { useState, useEffect } from "react";
import { useParams , useNavigate} from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import GradientButton from "../../components/buttons/GradientButton";
import DashboardMainLayout from "../../components/layouts/DashboardMainLayout";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";
import { useFormValidator } from "../../hooks/useFormValidator";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import BorderTextField from '../../components/textfields/BorderTextField';
import FormButton from '../../components/buttons/FormButton';

const EditTeam = () => {

  const { leagueId = '', teamId = '' } = useParams();
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker('EDIT-TEAM');
  const axiosPrivate = useAxiosPrivate();

  const [form, setForm] = useState({ title: "Test Team" });
  const [isProcessing, setProcessing] = useState(false);
  const { errors, validateForm, onBlurField } = useFormValidator(form);

  useEffect(() => {
    getTeamInfo();
  }, []);

  const onUpdateField = e => {
    const field = e.target.name;
    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };
    setForm(nextFormState);
    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const getTeamInfo = async () => {

    try {
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM, inputParams);

      const data = response?.data;
      if (data?.status) {
        setForm({ title: data.data.name });
        return
      }
      else {
        if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    validateForm({ form, errors, forceTouchErrors: true });

    if (errors?.title?.error) return;

    handleUpdateTeam();

  };
  const handleUpdateTeam = async () => {

    try {

      setProcessing(true);

      var inputParams = {
        ...form,
        leagueId: leagueId,
        teamId: teamId,
        teamName: form.title,
      };

      const response = await postRequest(axiosPrivate, URLConstant.EDIT_TEAM, inputParams);

      const data = response?.data;
      if (data?.status) {
        gaEventTracker('EDIT_TEAM_BUTTON', 'EDIT_TEAM');
        setProcessing(false);
        alert('Team Name changed successfully!');
        navigate(`/league/${data?.data?.leagueId}/team/${data?.data?.id}/`);
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
      gaEventTracker('ERROR', 'EDIT_TEAM');
    };
  };
  return (
    <DashboardMainLayout title="Edit Team">
      <Row className="m-4 justify-content-md-center">
        <Col lg={8} >
          <BorderTextField
            id="title"
            label="Team Name *"
            errorData={errors?.title}
            value={form.title}
            onChange={onUpdateField}
            onBlur={onBlurField}
          />
          <Stack direction="horizontal" gap={3}>
            <br></br>
          </Stack>
          <div className='d-flex flex-row-reverse'>
            <FormButton title="Update Team" handleSubmit={handleSubmit} isLoading={isProcessing} variant='white' skipCheckIcon/>
          </div>
        </Col>
      </Row>
    </DashboardMainLayout>
  );
};

export default EditTeam;