import { Box } from "@mui/material";
import React from "react";
import BorderButtonWithLink from "../buttons/BorderButtonWithLink";

const TestPercentileCard = (props) => {
  const { title, value = 'XX', link, slug, selectedSlug , handleClick } = props;
  let colorCode = '#F65A4A';
  switch (slug) {
    case "baseline-tests-completed": colorCode = '#F65A4A';
      break;
    case "players-benched": colorCode = '#FFC24B';
      break;
    case "post-injury-tests-completed": colorCode = '#003F60';
      break;
    default: colorCode = '#F65A4A';
      break;
  }
  return (
    <Box sx={{
      backgroundColor: colorCode,
      boxShadow: 1,
      width: '100%',
      height: 'auto',
      margin: '5px',
      padding: '10px',
      borderRadius: '2px',
      cursor: 'pointer'
    }}
      onClick={handleClick}
    >
      <div className="test-percentile-card-container">
        <div className="percentile-card-value-tile-container">
          <span className="test-percentile-card-value">{value}</span>
          <span className="test-percentile-card-title">{title.toUpperCase()}</span>
        </div>
        <BorderButtonWithLink title='VIEW LIST' colorCode={colorCode} selected={slug===selectedSlug}
        // handleClick={handleClick}
        />
      </div>
    </Box>
  )
}

export default TestPercentileCard;