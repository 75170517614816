import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import ResetTestModal from '../modal/ResetTestModal';

const PIEChart = (props) => {
  const { pieData = [
    {
      name: "XXXXX XXX XXXX",
      value: 0,
      colorId: 1
    },
    {
      name: "Total XXX",
      value: 100,
      colorId: 2
    }
  ], title = 'XXXX XXXX', flexColumn } = props;

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.onresize = () => setWidth(window.innerWidth);
  }, [])

  return (
    <div className={`widget-pie-container ${flexColumn ? (width <= 800 && 'flex-column') : 'flex-md-row'}`}>
      <div className="pie-chart">
        <PieChart width={width >= 800 ? 200 : flexColumn ? 100 : 200} height={width >= 800 ? 200 : flexColumn ? 100 : 200}>
          <Pie
            data={pieData}
            outerRadius={width >= 800 ? 80 : flexColumn ? 40 : 80}
            fill={pieData[1].colorId === 2 ? "#F65A4A" : "#91D465"}
            dataKey="value"
          >
            <Cell fill={pieData[0].colorId === 1 ? "#91D465" : '#F65A4A'} />
          </Pie>
        </PieChart>
      </div>
      <div className="widget-pie">
        <span className="baseline-value mt-4">{pieData[0].value}%</span>
        <span className="widget-baseline-text mb-4">{title.toUpperCase()}</span>
        <ResetTestModal leagueId={props.leagueId} teamId={props.teamId} />
      </div>
    </div>
  );
}
export default PIEChart;

