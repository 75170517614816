import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import moment from "moment";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Stack from 'react-bootstrap/Stack';
import Skeleton from '@mui/material/Skeleton';

import ListGroup from 'react-bootstrap/ListGroup';
import { NavLink, Outlet, useParams } from "react-router-dom";

import BreadcrumbMenu from '../nav/BreadcrumbMenu';
import { useAuth } from "../../hooks/useAuth";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";

import URLConstant from "../../constant/URLConstant";

const LeagueLayout = () => {

    const { user } = useAuth();
    const { leagueId = '' } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [leagueInfo, setLeagueInfo] = useState({});
    const [teams, setTeams] = useState([]);
    const [subscriptionInfo, setSubscriptionInfo] = useState({});
    const [isLoading, setLoading] = useState(false);

    const menuItem = {
        parentMenu: {
            title: 'League',
            href: leagueId === '' ? '#' : `/league/${leagueId}/`,
        },
    };

    useEffect(() => {
        getLeagueInfo();
    }, []);

    const getLeagueInfo = async () => {

        try {

            setLoading(true);

            if (!leagueId) return;

            const response = await postRequest(axiosPrivate, URLConstant.LEAGUE_INFOS, { userID: user?.id, leagueId });

            const data = response?.data;

            if (data?.status && data?.data) {

                var responseData = data?.data;

                setLeagueInfo(responseData?.leagueInfo);
                setSubscriptionInfo(responseData?.leagueSubscriptionInfo);
                setTeams(responseData?.leagueTeams);
            }
            else if (data?.message) alert(data.message);

            setLoading(false);
            return
        } catch (err) {
            console.log(err)
        };
    };

    const selectedNavLink = ({ isActive }) => isActive ? 'list-group-item list-group-item-action list-group-item-primary' : 'list-group-item list-group-item-action';


    return (
        <Container className="p-2 app-min-hight">
            <Row>
                <BreadcrumbMenu menuItem={menuItem} hideHome></BreadcrumbMenu>
            </Row>
            <Row >
                <Col xs={12} s={12} md={12} lg={9} xl={9} xxl={9} className="mt-2">
                    {
                        isLoading && <Skeleton variant="text" animation="wave" />
                    }
                    {
                        !isLoading && !leagueInfo?.id && <p className="text-center"> You don't have an access to this page. </p>
                    }
                    {
                        !isLoading && leagueInfo?.id && <Outlet context={[leagueInfo]} />
                    }
                </Col>
                <Col xs={12} s={12} md={12} lg={3} xl={3} xxl={3} className="mt-2">
                    <Stack gap={3}>
                        <ListGroup className="mb-5">
                        <ListGroup.Item variant="secondary">
                            League Actions
                        </ListGroup.Item>
                        <NavLink to={`/league/${leagueId}`} className={selectedNavLink}>
                            League Dashboard
                        </NavLink>
                        </ListGroup>
                    </Stack>
                </Col>
                {/* <Col xs={12} s={12} md={12} lg={3} xl={3} xxl={3} className="mt-2">
                    <Stack gap={3}>
                        <ListGroup className="mb-5">
                            <ListGroup.Item variant="secondary">
                                Subscription Overview
                            </ListGroup.Item>
                            <ListGroup.Item>
                                {
                                    subscriptionInfo.title ?
                                        <div className="text-muted">
                                            <p className="mt-1 mb-1">Plan: {subscriptionInfo.title}</p>
                                            <p className="mt-1 mb-1">Head Count: #{subscriptionInfo.headCount}</p>
                                            <p className="mt-1 mb-1">Expires: <span className="color-green text-bold">{moment(subscriptionInfo?.subscriptionExpiryDate).format('DD MMM YYYY')}</span></p>
                                            <span className="mt-1">Auto Renewal: Yes</span>
                                        </div>
                                        :
                                        <p>
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                        </p>
                                }
                            </ListGroup.Item>
                        </ListGroup>
                    </Stack>
                    <Stack gap={3}> */}
                        {/* <ListGroup className="mb-5">
                            <ListGroup.Item variant="secondary">
                                League Actions
                            </ListGroup.Item> */}
                            {
                                // leagueInfo?.id &&
                                // <>
                                //     <NavLink to="league-admins" className={selectedNavLink} >
                                //         List League Admins
                                //     </NavLink>
                                //     <NavLink to="create-league-admin/?role=league_admin" className={selectedNavLink} >
                                //         Create League Admin
                                //     </NavLink>
                                //     <NavLink to="teams" className={selectedNavLink} >
                                //         List Teams
                                //     </NavLink>
                                //     <NavLink to="create-team" className={selectedNavLink} >
                                //         Create Team
                                //     </NavLink>
                                //     <NavLink to="configuration" className={selectedNavLink} >
                                //         Configuration
                                //     </NavLink>
                                // </>
                            }
                            {
                                // !leagueInfo?.id && <>
                                //     <Skeleton variant="text" animation="wave" />
                                //     <Skeleton variant="text" animation="wave" />
                                // </>
                            }
                        {/* </ListGroup> */}
                    {/* </Stack>
                </Col> */}
            </Row>
        </Container>
    );
};

export default LeagueLayout;