import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";

import URLConstant from "../../constant/URLConstant";
import CONSTANTS from "../../constant/Constants";
import MainLayout from "../../components/layouts/MainLayout";
import GradientContainer from "../../components/cards/GradientContainer";
import GradientTable from "../../components/table/GradientTable";
import TeamWidget from "../../components/widget/TeamWidget";
import Search from "../../components/Search";
import BaselineReminderCard from "../../components/cards/SendEmailReminderCard";

var momentTZ = require('moment-timezone')

const DEFAULT_TEAM_WIDGETS = [
  {
    "id": 1,
    "title": "Baseline Tests Needed",
    "targetHierarchy": "team",
    "sequence": 1,
    "status": 1,
    "slug": "baseline-tests-needed"
  },
  {
    "id": 2,
    "title": "XXXX Benched",
    "targetHierarchy": "team",
    "sequence": 2,
    "status": 1,
    "slug": "players-benched"
  },
  {
    "id": 3,
    "title": "Post Injury Taken",
    "targetHierarchy": "team",
    "sequence": 3,
    "status": 1,
    "slug": "post-injury-tests-completed"
  }
];

const PostInjuryTaken = () => {

  const { leagueId = '', teamId = '' } = useParams();
  const navigate = useNavigate();

  const axiosPrivate = useAxiosPrivate();
  const [members, setMembers] = useState([]);
  const [widgetInfo, setWidgetInfo] = useState({ timestamp: Date.now(), widgets: DEFAULT_TEAM_WIDGETS });
  const [historicalData, setHistoricalDataInfo] = useState({});
  const [isMembersUpdated, setIsMembersUpdated] = useState(false);
  const [team, setTeam] = useState(false);
  const [roles, setRoles] = useState([]);
  const [tableData, setTableData] = useState(members);
  const [tableType, setTableType] = useState('MEMBERS');
  const [tableInfo, setTableInfo] = useState({
    headList: ['Name', 'Baseline', 'Actions'],
    actions: ['Test Scores', 'Profile', 'Remove', 'Change Role'],
  });
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    getTeamInfo();
    getUserRoles();
  }, []);

  useEffect(() => {
    getMembersData();
  }, [isMembersUpdated]);

  useEffect(() => {
    getPostInjuryTestsTaken();
  }, [members]);

  const getPostInjuryTestsTaken = async () => {
    setTableType('POST INJURY TAKEN');
    setTableData(members.filter((member) => member.lastPostInjury !== null));
    setTableInfo({
      headList: ['Name', 'Actions'],
      actions: ['Test Scores', 'Profile', 'Remove', 'Change Role'],
    })
  };

  const getMembersData = async () => {

    try {

      setProcessing(true);

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        timezone: momentTZ.tz.guess()
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_MEMBERS, inputParams);

      const data = response?.data;
      if (data?.status) {
        setProcessing(false);
        setMembers(data.data);
        setIsMembersUpdated(false);
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        setIsMembersUpdated(false);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const getTeamInfo = async () => {

    try {
      setProcessing(true);

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM, inputParams);

      const data = response?.data;
      if (data?.status) {
        setTeam(data.data);

        if (data?.data?.widgets.length > 0) {
          setWidgetInfo({
            widgets: data?.data?.widgets,
            timestamp: Date.now()
          })
        };

        setHistoricalDataInfo(data?.data?.historicalData || {})
        setProcessing(false);
        return
      }
      else {
        // if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const getUserRoles = async () => {
    try {
      setProcessing(true);
      var inputParams = {
        roleType: 'team',
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM_USER_ROLES, inputParams);

      const data = response?.data;
      if (data?.status) {
        setRoles(data.data.userRoles);
        setProcessing(false);
        return
      }
      else {
        // if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
      setProcessing(false);
    };
  };


  const copyTextToClipboard = async (event, text) => {

    event.preventDefault();

    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        alert("Value copied successfully!");
      })
        .catch((err) => {
          console.error("something went wrong", err);
        });
    }
    else {

      const textArea = document.createElement("textarea");

      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        alert("Value copied successfully!");
      }
      catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }
  }

  const handleRemoveMember = async (param) => {
    try {
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        ...param,
      };
      const response = await postRequest(axiosPrivate, URLConstant.REMOVE_MEMBER, inputParams);

      const data = response?.data;
      if (data?.status) {
        alert('Member removed successfully!');
        setIsMembersUpdated(true);
        getMembersData();
        return
      }
      else {
        if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
    }
  }

  const handleRoleChange = async (param) => {
    try {

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        ...param,
        // teamMemberId: +teamMemberId,
        // memberRoleSlug: memberRoleSlug,
      };

      const response = await postRequest(axiosPrivate, URLConstant.CHANGE_ROLE, inputParams);

      const data = response?.data;
      if (data?.status) {
        setProcessing(false);
        setIsMembersUpdated(true);
        alert('Role Changed successfully!');
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
    }
  }

  const handleSearch = (event, searchText, searchFilters) => {
    event.preventDefault();
    if (!searchText) return;
    setTableType(`SEARCH RESULTS FOR "${searchText}"`)

    let trimmedText = searchText?.trim();

    let searchResults = members.filter((member) => member?.firstName.includes(trimmedText) || member?.lastName.includes(trimmedText) || member?.email.includes(trimmedText) && ((searchFilters.baselineNeed && member.lastBaseline === null) || (searchFilters.benched && member.benched === 1) || (searchFilters.post_injuryTaken && member.lastPostInjury !== null)));
    setTableData(searchResults);
    setTableInfo({
      headList: ['Name', 'Actions'],
      actions: ['Test Scores', 'Profile', 'Remove', 'Change Role'],
    })
  }

  const handleAction = (event, action, param) => {
    if (action !== 'change role') {
      event.preventDefault();

    }
    switch (action) {
      case 'test scores':
        navigate(`member/${param.teamMemberId}/user/${param.userID}/test/results`)
        break;
      case 'profile':
        navigate(`member/${param.teamMemberId}/profile/${param.userID}`)
        break;
      case 'remove':
        handleRemoveMember(param);
        break;
      case 'change role':
        handleRoleChange({ ...param, memberRoleSlug: event });
        break;
    }
  }

  return (
    <>
      <GradientContainer title={team.name} cardType='team' pendingWithUserAcceptance={historicalData?.pendingWithAdminApproval || false} editUrl={`/league/${leagueId}/team/${teamId}/edit-team`} />
      <MainLayout>
        <TeamWidget joinCode={team.joinCode} widgetInfo={widgetInfo} />
        <Search label={CONSTANTS.SEARCH_LABEL} onClick={handleSearch}
          isLoading={isProcessing}
          defaultSearchFilters={{
            baselineNeed: false,
            post_injuryTaken: true,
            benched: false
          }} />
        <div className="table-container">
          <GradientTable
            title={tableType}
            rows={tableType !== 'MEMBERS' ? tableData : members.filter((member) => member.roleId === 3)}
            roles={roles}
            headList={tableInfo.headList}
            actions={tableInfo.actions}  //buttons passed as array
            onAction={handleAction}
            isLoading={isProcessing}
          />
        </div>
      </MainLayout >
    </>
  );
}

export default PostInjuryTaken;