import { Outlet } from "react-router-dom";
import Container from 'react-bootstrap/Container';

const GradientLayout = () => {

  return (
    <Container fluid='xll' className="main-gradient-section">
        <Outlet />
    </Container>
  )
};

export default GradientLayout;