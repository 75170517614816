import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from '../../components/DatePicker';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import { useFormValidator } from "../../hooks/useFormValidator";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

import URLConstant from "../../constant/URLConstant";
import DashboardMainLayout from "../../components/layouts/DashboardMainLayout";
import UserRoleDropDown from '../../components/drop-down/UserRoleDropDown'
import BorderTextField from '../../components/textfields/BorderTextField';
import FormButton from '../../components/buttons/FormButton';

var defaultMemberForm = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "male",
  birthDate: null,
  role: null,
};

const CreateTeamMember = () => {

  const { leagueId = '', teamId = '' } = useParams();
  const navigate = useNavigate();
  
  var queryParams = Object.fromEntries([...(new URLSearchParams(window.location.search))]);
  const memberType = queryParams?.memberType == 'hitcheck-member' ? 2 : 1;
  
  const [form, setForm] = useState({ ...defaultMemberForm });
  const [roles, setRoles] = useState([]);
  const [isProcessing, setProcessing] = useState(false);
  const {errors, validateForm, onBlurField} = useFormValidator(form);
  
  const axiosPrivate = useAxiosPrivate();
  const gaEventTracker = useAnalyticsEventTracker('CREATE-TEAM-MEMBER');

  useEffect(() => {
    getUserRoles();
  }, []);

  const getUserRoles = async () => {

    setProcessing(true);

    try {

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        roleType: 'team',
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM_USER_ROLES, inputParams);

      const data = response?.data;
      if (data?.status) {

        setRoles(data.data.userRoles);

        if (!form?.role) {
          var selectedValue = queryParams?.role?.toLowerCase() == 'patient' ? 'player' : queryParams?.role?.toLowerCase();
          if (data.data.userRoles?.length) {
            var selectedRole = (data.data.userRoles?.find((role) => role.slug === selectedValue))
            if (selectedRole?.slug) {
              onUpdateByField('role', selectedRole?.slug?.toLowerCase());
            };
          };
        };

        setProcessing(false);
        return
      }
      else {
        // if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const onUpdateField = e => {

    const field = e.target.name;

    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };

    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const onUpdateByField = (fieldName, value) => {
    const field = fieldName;

    const nextFormState = {
      ...form,
      [field]: value,
    };

    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleRoleChange = (e) => {
    onUpdateByField('role', e.target.value?.toLowerCase());
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    validateForm({ form, errors, forceTouchErrors: true });

    // console.log(errors?.role?.error, form);
    
    if (memberType == 1) {
      if (!form.firstName || !form.lastName || !form.email || !form.gender || !form.birthDate || !form.role) return;
      else if (errors?.firstName?.error || errors?.lastName?.error || errors?.email?.error || errors?.gender?.error || errors?.birthDate?.error || errors?.role?.error) return;
    }
    else if (memberType == 2) {
      if (!form.email || !form.role) return;
      else if (errors?.email?.error || errors?.role?.error) return;
    };
    
    handleCreateTeamMember();
  };

  const handleCreateTeamMember = async () => {

    try {

      setProcessing(true);

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        userInfo: JSON.stringify(form),
        memberRoleSlug: form.role,
        memberType: memberType
      };

      if (!form?.role) {
        var selectedValue = queryParams?.role?.toLowerCase() == 'patient' ? 'player' : queryParams?.role?.toLowerCase();
        if (roles?.length && selectedValue) {
          var selectedRole = (roles?.find((role) => role.slug === selectedValue))
          if (selectedRole?.slug) {
            inputParams.memberRoleSlug = selectedRole?.slug?.toLowerCase();
          };
        };
      };

      if (moment.isMoment(form.birthDate)) {
        inputParams.birthDate = form.birthDate.format('YYYY-MM-DD');
      }
      else {
        inputParams.birthDate = moment.utc(form.birthDate).format('YYYY-MM-DD');
      }

      const response = await postRequest(axiosPrivate, URLConstant.CREATE_TEAM_MEMBER, inputParams);

      const data = response?.data;

      if (data?.status) {

        const resetForm = ({ ...defaultMemberForm, role: form.role });

        gaEventTracker('SAVE_BUTTON', 'CREATE_TEAM_MEMBER');
        alert('Member Added Successfully!')

        setForm(resetForm);
        setProcessing(false);
        navigate(`/league/${leagueId}/team/${teamId}/`)
        return;
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
      gaEventTracker('ERROR', 'CREATE_TEAM_MEMBER');
    };
  };

  return (
    <DashboardMainLayout title="Create Team Member">
      <div className='mx-4 my-4'>
        {memberType == 1 && (
          <Row className="mt-3 justify-content-md-center">
            <Col lg={10} >
              <BorderTextField
                id="firstName"
                label="First Name"
                placeholder="Required"
                errorData={errors?.firstName}
                value={form.firstName}
                onChange={onUpdateField}
                onBlur={onBlurField}
              />
            </Col>
          </Row>
        )}

        {memberType == 1 && (
          <Row className=" mt-3 justify-content-md-center">
            <Col lg={10} >
              <BorderTextField
                id="lastName"
                label="Last Name"
                placeholder="Required"
                errorData={errors?.lastName}
                value={form.lastName}
                onChange={onUpdateField}
                onBlur={onBlurField} />
            </Col>
          </Row>
        )}

        <Row className=" mt-3 justify-content-md-center">
          <Col lg={10} >
            <BorderTextField
              id="email"
              label="Email"
              placeholder="Required"
              errorData={errors?.email}
              value={form.email}
              onChange={onUpdateField}
              onBlur={onBlurField}
            />
          </Col>
        </Row>
        
        {memberType == 1 && (
          <Row className=" mt-3 justify-content-md-center">
            <Col lg={10} >
              <DatePicker
                label="Birth Date"
                variant='outlined'
                placeholder="Required"
                value={form.birthDate}
                error={errors?.birthDate?.error ? true : false}
                helperText={errors.birthDate.dirty && errors.birthDate.error ? errors.birthDate.message : ""}
                onChange={
                  (value) => onUpdateByField('birthDate', value)
                }
                mobile />
            </Col>
          </Row>
        )}
        
        {memberType == 1 && (
          <Row className=" mt-3 justify-content-md-center">
            <Col lg={10} >
              <FormControl fullWidth className="form-control-radio-button-group">
                <FormLabel id="gender-label-green">Gender</FormLabel>
                <RadioGroup
                  row
                  className='form-control-radio'
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'male'}
                  name="gender"
                  variant="filled"
                  error={errors?.gender?.error ? true : false}
                  onChange={onUpdateField}
                  value={form.gender || null}
                  size="small"
                >
                  <FormControlLabel value="male" className="form-control-radio-label-blue" control={<Radio size="small" />} label="Male" />
                  <FormControlLabel value="female" className="form-control-radio-label-blue" control={<Radio size="small" />} label="Female" />
                  <FormControlLabel value="non-binary" className="form-control-radio-label-blue" control={<Radio size="small" />} label="Non-binary" />
                </RadioGroup>
              </FormControl>
              {(errors?.gender?.dirty && errors?.gender?.error) &&
                <Stack direction="horizontal" gap={3}>
                  <p className="text-danger css-1wc848c-MuiFormHelperText-root">{errors.gender?.message}</p>
                </Stack>
              }
            </Col>
          </Row>
        )}

        <Row className="mt-3 justify-content-md-center">
          <Col lg={10}>
            <FormControl fullWidth>
              <FormLabel className="border-text-field-label">Role</FormLabel>
              <UserRoleDropDown label="Role" roles={roles} onChange={handleRoleChange} value={form?.role || queryParams?.['role']} />
            </FormControl>
          </Col>
        </Row>

        <Row className="mt-4 justify-content-md-center">
          <Col lg={10}>
            <div className='d-flex flex-row-reverse'>
              <FormButton title={memberType == 2 ? "Add Member" : "Create Member"} handleSubmit={handleSubmit} isLoading={isProcessing} variant='white' skipCheckIcon />
            </div>
          </Col>
        </Row>
      </div>
    </DashboardMainLayout>
  )
};

export default CreateTeamMember;