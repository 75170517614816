import React, { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import '../../assets/styles/Pricing.css';
import PlanCard from './components/PlanCard';
import CheckIcon from '../../assets/icons/checkmark-icon.png'
import ContainedButton from '../../components/buttons/ContainedButton';
import TextField from './components/TextField';
import Loader from "../../components/modal/Loader";
import HubSpotContactForm from '../../components/forms/HubSpotContactForm';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";

const TierPricing = () => {

  const [orgType, setOrgType] = useState(1);
  const [memberCount, setMemberCount] = useState(1);
  const [features, setFeatures] = useState([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [showContactForm, setContactFormVisibility] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchSubscriptionPlans();
  }, [, memberCount]);

  const fetchSubscriptionPlans = async () => {

    try {

      setLoading(true);

      const response = await postRequest(axiosPrivate, URLConstant.GET_SUBSCRIPTION_PLANS, { headCount: memberCount });

      const data = response?.data;

      if (data?.message) alert(data.message);

      if (data?.status && data?.data) {
        setSubscriptionPlans(data?.data?.subscriptionPlans);
        setFeatures(data?.data?.features);
      };
      setLoading(false);
      return;
    }
    catch (err) {
      console.log(err);
    };
  };

  return (
    <Container className='d-flex-row justify-content-between'>

      <Loader isLoading={isLoading} />

      <Row className="justify-content-center pricing-body">
        <Col>
          {/* <h6 className='mx-3 px-3'>Create a team plan that works for you or choose an <a href='/sign-up' class='solo-signup'>Individual plan.</a></h6> */}
          <h6 className='header'>Create a plan that works for you.</h6>
          <ToggleButtonGroup
            exclusive
            onChange={(e) => setOrgType(parseInt(e.target.value))}
            aria-label="OrgType">
            <ToggleButton value={1} className='toggle-button' selected={orgType == 1 ? true : false}>Sports Organization</ToggleButton>
            <ToggleButton value={2} selected={orgType == 2 ? true : false}>Health Care Provider</ToggleButton>
          </ToggleButtonGroup>
          <div className='member-count-container'>
            <span className='member-count-text'>Set # of <span>{orgType === 1 ? 'athletes ' : 'patients '}</span>for pricing</span>
            <TextField defaultValue={memberCount} onChange={(val) => setMemberCount(val)} />
          </div>
        </Col>
      </Row>
      <Row className='m-0'>
        <Col className='p-0'>
          <PlanCard
            features={features}
            subscriptionPlans={subscriptionPlans}
            orgType={orgType}
            memberCount={memberCount}
            updateContactFormVisibility={() => setContactFormVisibility(!showContactForm)}
          />
        </Col>
      </Row>
      <div className='mx-2 contact-container'>
        <h6 className='contact-text'>Want help tailoring your plan?</h6>
        <ContainedButton title="Contact a representative" buttonType="contact" hoverColor='#0C243F' handleClick={() => setContactFormVisibility(!showContactForm)} />
      </div>

      <br /><br />

      <HubSpotContactForm
        showContactForm={showContactForm}
        onClose={() => setContactFormVisibility(!showContactForm)}
      />

    </Container >
  );
}

export default TierPricing;