import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from 'lodash';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import DashboardMainLayout from "../../components/layouts/DashboardMainLayout";
import Dropzone from "../../components/file-upload/Dropzone";

import { postRequest } from "../../helper/axios";
import { postForm } from "../../helper/axios";
import { downLoadFile } from "../../helper/util";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

import URLConstant from "../../constant/URLConstant";
import FormButton from '../../components/buttons/FormButton';
import Loader from "../../components/modal/Loader";

import SharedStyles from '../../assets/styles/SharedStyles';

const SUPPORTED_FILE_TYPES = ['text/csv'];

const UploadRoster = () => {
  
  const { leagueId = '', teamId = '' } = useParams();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const gaEventTracker = useAnalyticsEventTracker('CHANGE-AVATAR');

  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [errorRecords, setErrorRecords] = useState(null);

  const handleSubmit = (event) => {

    event.preventDefault();

    if (!isValidImageFile(file)) return alert("Upload valid file and try again!");

    uploadFile();
  };

  const uploadFile = async () => {

    try {

      setLoading(true);

      const fileData = new FormData();
      fileData.append('CSV_roster', file);
      fileData.append('teamId', teamId);
      fileData.append('leagueId', leagueId);

      const response = await postForm(axiosPrivate, URLConstant.UPLOAD_ROASTER, fileData);

      const data = response?.data;
      
      if (data?.status) {
        
        alert('Roster imported successfully!');
        setLoading(false);
        
        return navigate(`/league/${leagueId}/team/${teamId}/`, { replace: true });
      }
      else {

        if (data?.message) {
          alert(data.message);
        };

        if (data?.data) {
          var errorRecords = _.filter(data.data, (item) => _.has(item, 'error'));
          setErrorRecords(errorRecords);
        };
        
        setLoading(false);
        return;
      };
    }
    catch (err) {
      setLoading(false);
      setFile(null);
      alert('Something went wrong. Please try again.');
      console.log(err);
    }
  }

  const isValidImageFile = (file) => {

    if (!file) return false;

    if (SUPPORTED_FILE_TYPES.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  }

  const onDrop = useCallback((acceptedFiles) => {

    acceptedFiles.map((file) => {
      
      if (isValidImageFile(file)) {
        setFile(file);
        setErrorRecords(null);
      };
      return file
    });
  }, []);

  const handleRosterTemplateDownload = async () => {
    
    try {
      
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      setLoading(true);

      const response = await postRequest(axiosPrivate, URLConstant.DOWNLOAD_ROASTER_TEMPLATE, inputParams);

      const data = response?.data;

      setLoading(false);

      if (data) {
        if (window.confirm('Please confirm to start the download.')) {
          await downLoadFile(data, 'ROSTER_TEMPLATE.csv');
        };
        return;
      }
      else {
        if (data?.message) alert(data.message);
        return;
      };
    }
    catch (err) {
      console.log(err)
    };
  };

  return (
    <>
      <DashboardMainLayout title="Upload Roster">

        <Row className="mx-3 mt-4 mb-2 justify-content-md-center">
          <Col lg={12} >
            <Stack direction="column" gap={2} className="mx-auto mt-2">
              <Dropzone maxFiles={1} onDrop={onDrop} accept={{ 'text/csv': [] }} choosenFile={file} />
            </Stack>
          </Col>
        </Row>

        {errorRecords &&
          <Row className="mt-4 mx-3">
            <p className='text-danger'>Please fix the below error(s) and try again.</p>
            <Col lg={12}>
              <ul className='text-danger'>
                {errorRecords.map((userObj) => {
                  return (
                    <li>{userObj?.error}</li>
                  )
                })}
              </ul>
            </Col>
          </Row>
        }

        {file &&
          <Row className="my-4 justify-content-md-center">
            <Col lg={6} >
              <Stack direction="column" gap={2} className="mx-auto mt-2">
                <FormButton title="Upload File" handleSubmit={handleSubmit} isLoading={isLoading} variant="white" skipCheckIcon />
              </Stack>
            </Col>
          </Row>
        }
      </DashboardMainLayout>

      <div className="list-group-container upload-roster-instructions px-3 py-2 mb-4">
        <div>IMPORTANT! The .CSV roster format MUST match the HitCheck Roster Template</div>
        <Button 
          sx={SharedStyles.whiteButton}
          variant="outlined"
          size='small'
          onClick={(e) => {
            e.preventDefault();
            handleRosterTemplateDownload();
          }}
          rounded>
            DOWNLOAD TEMPLATE
        </Button>
      </div>

      <Loader isLoading={isLoading} />
    </>
  );
};

export default UploadRoster;