import React, { memo } from "react";
import moment from "moment";

import Avatar from '@mui/material/Avatar';
import Stack from 'react-bootstrap/Stack';

import { formatPhoneNumberIntl } from 'react-phone-number-input';

import Constants from "../../constant/Constants";
import FormButton from '../buttons/FormButton';

const AVATAR_PATH = Constants.CONFIG.AVATAR_PATH;

const ProfileInfoCard = (props) => {

  const { email, firstName, lastName, username, phone, callingCode, birthDate, gender, isEmailVerified, isPhoneNumberVerified, avatar } = props.user;

  return (

    <div className="d-grid">

      <Stack direction="horizontal" gap={3}>
        {/* {avatar && <Avatar alt={firstName} src={AVATAR_PATH + avatar} sx={{ height: '150px', width: '150px' }} />}
        {!avatar && <Avatar alt={firstName} src={AVATAR_PATH} />} */}
        {/* <h3 className="text-capitalize m-0">{firstName + " " + (lastName || "")}</h3> */}
        <span className='profile-text-label'>Name :</span>
        <span className='profile-text'>{firstName + " " + (lastName || "")}</span>
      </Stack>

      {
        username &&
        <Stack direction="horizontal" gap={3}>
          <span className='profile-text-label'>Username :</span>
          <span className='profile-text'>{username}</span>
        </Stack>
      }

      {
        email &&
        <Stack direction="horizontal" gap={3}>
          <span className='profile-text-label'>Email :</span>
          <span className='profile-text'>{email}</span>
          {
            !isEmailVerified && !props.hideVerify &&
            <span >
              <FormButton size="sm" skipCheckIcon title="Verify Email" handleSubmit={() => props.sendVerificationRequest('email')} />
            </span>
          }
        </Stack>
      }

      {
        phone &&
        <Stack direction="horizontal" gap={3}>
          <span className='profile-text-label'>Phone :</span>
          <span className='profile-text'>{formatPhoneNumberIntl((callingCode || '') + (phone || ''))}</span>
          {
            !isPhoneNumberVerified &&
            <FormButton size="sm" skipCheckIcon title="Verify Phone" handleSubmit={() => props.sendVerificationRequest('phone')} />
          }
        </Stack>
      }

      {
        birthDate && <Stack direction="horizontal" gap={3}>
          <span className='profile-text-label'>DOB :</span>
          <span className='profile-text'>{moment.utc(birthDate).format('DD MMM YYYY')}</span>
        </Stack>
      }
      {
        gender && <Stack direction="horizontal" gap={3}>
          <span className='profile-text-label'>Gender :</span>
          <span className='profile-text'>{gender.charAt(0).toUpperCase() + gender.slice(1)}</span>
        </Stack>
      }

    </div>

  );
}

export default memo(ProfileInfoCard);