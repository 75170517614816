import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const StyledFilledTextField = styled(OutlinedInput, {
  name: "StyledFilledTextField"
})({
  "& .MuiInputBase-input": {
    backgroundColor: '#D9D9D9',
    borderRadius: '4px',
    padding: '10px 10px',
    color: '#0C243F',
    fontSize: '16px',
    fontFamily:'Montserrat',
  },

});
const StyledBorderTextField = styled(OutlinedInput, {
  name: "StyledBorderTextField"
})({
  "& .MuiInputBase-input": {
    border: '1px solid #003F60',
    borderRadius: '4px',
    padding: '5px 10px',
    color: '#0C243F',
    fontSize: '16px',
    fontFamily:'Montserrat',
  },

});

const minDate = "02-01-1940";

export default function DatePicker(props) {

  const [value, setValue] = useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value])

  const handleChange = (newValue) => {
    setValue(newValue);
    if (props.onChange) props.onChange(newValue);
  };

  if (props.mobile) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack>
          <InputLabel className={`border-text-field-label${props.variant === 'outlined' ?'':'-white'}`}>{props.label}</InputLabel>
          <MobileDatePicker
            inputFormat="MM/DD/YYYY"
            value={value}
            onChange={handleChange}
            disableFuture={true}
            minDate={new Date(props.minDate || minDate)}
            disableHighlightToday={true}
            invalidDateMessage="Computer says no"
            renderInput={(params) => props.variant === 'outlined' ? <StyledBorderTextField
              {...params}
              id="dateOfBirth"
              fullWidth
              variant="filled"
              error={!!props.error} helperText={props.helperText}
              placeholder={props.placeholder}
            />
              :
              <StyledFilledTextField
                {...params}
                id="dateOfBirth"
                fullWidth
                variant="filled"
                error={!!props.error} helperText={props.helperText}
                placeholder={props.placeholder}
              />
            }
          />
          {props?.helperText &&
            <div className='mt-1 label-error'>{props?.helperText}</div>
          }
        </Stack>
      </LocalizationProvider>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <InputLabel className="border-text-field-label-white">{props.label}</InputLabel>
        <DesktopDatePicker
          variant="inline"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          disableFuture={true}
          minDate={new Date(props.minDate || minDate)}
          disableHighlightToday={true}
          renderInput={(params) => props.variant === 'outlined' ? <StyledBorderTextField
            fullWidth
            variant="filled"
            helperText={params?.placeholder} {...params}
            placeholder={props.placeholder}
          />
            :
            <StyledFilledTextField
              fullWidth
              variant="filled"
              helperText={params?.placeholder} {...params}
              placeholder={props.placeholder}
            />
          }
        />
      </Stack>
    </LocalizationProvider>
  );
}