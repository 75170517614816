import { Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';

const BreadcrumbMenu = ({ menuItem, hideHome = false }) => {

    return (
        <Container fluid="sm" className="mt-3 mb-3">
            <div className="hit-breadcrumb">
                <Breadcrumb>
                    {
                        !hideHome && 
                        <Breadcrumb.Item>
                            <Link to={"/user/dashboard"} >Home</Link>
                        </Breadcrumb.Item>
                    }
                    {
                        menuItem?.grandParentMenu?.title &&
                        < Breadcrumb.Item>
                            <Link to={menuItem?.grandParentMenu?.href || "#"} >{menuItem?.grandParentMenu?.title}</Link>
                        </Breadcrumb.Item>
                    }
                    {
                        menuItem?.parentMenu?.title &&
                        < Breadcrumb.Item>
                            <Link to={menuItem?.parentMenu?.href || "#"} >{menuItem?.parentMenu?.title}</Link>
                        </Breadcrumb.Item>
                    }
                    {
                        menuItem?.currentMenu?.title &&
                        <Breadcrumb.Item active>{menuItem?.currentMenu?.title}</Breadcrumb.Item>
                    }
                </Breadcrumb >
            </div>
        </Container>
    );

}

export default BreadcrumbMenu;