import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const MainLayout = ({ children }) => {
    return (
        <Container className="p-2 hit-main-layout">
            <Row>
                {children}
            </Row>
        </Container>
    )
};

export default MainLayout;