import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as UUID_v4 } from 'uuid';
import { isDesktop, isMobileOnly, deviceType, osVersion, osName, browserName, browserVersion, mobileModel, mobileVendor } from 'react-device-detect';
import ReactGA from "react-ga4";

import { useLocalStorage } from "./useLocalStorage";
import { setAuthToken } from "../helper/axios";

import Constants from "../constant/Constants";

const AuthContext = createContext();

const getDeviceInfo = () => {

    var deviceName = `${osName} ${osVersion} - ${browserName} - ${browserVersion}`;
    var customDeviceType = deviceType || "other";

    if (isDesktop && customDeviceType == "other") customDeviceType = "laptop-or-desktop";
    if (isMobileOnly) customDeviceType = "mobile";

    if (mobileModel && mobileVendor && mobileModel != 'none' && mobileVendor != 'none') {
        deviceName = `${mobileModel} ${mobileVendor} - ${deviceName}`
    };

    const deviceData = {
        deviceID: UUID_v4(),
        deviceType: deviceType,
        deviceName: deviceName,
        deviceModel: mobileModel && mobileModel != 'none'? mobileModel : osName,
        OS_Name: osName,
        OS_Version: osVersion
    };

    return deviceData;
};

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useLocalStorage(Constants.ASYNC_STORAGE_KEYS.USER_CORE_PROPERTIES, null);
    const [coreData, setCoreProps] = useLocalStorage(Constants.ASYNC_STORAGE_KEYS.CORE_DATA, getDeviceInfo());

    const navigate = useNavigate();

    const setUserCoreProps = async (data) => {

        await setUser(data?.user);

        const nextCoreData = {
            ...coreData,
            accessToken: data.accessToken,
            userID: data.userID,
        };

        await setCoreProps(nextCoreData);
        await setAuthToken(data.accessToken);

        ReactGA.set({ userId: data.userID }); // Set userID for Analytics reporting.
        return;
    };

    // call this function to sign out logged in user
    const logout = async () => {

        const nextCoreData = {
            ...coreData,
        };

        delete nextCoreData.accessToken;
        delete nextCoreData.userID;

        await setCoreProps(nextCoreData);
        await setAuthToken(null);
        await setUserCoreProps({});

        ReactGA.set({ userId: null }); // Reset userID.
        navigate("/login", { replace: true });
    };

    const updateUser = (user) => {
        setUser(user);
    };

    const value = useMemo(
        () => ({
            user,
            coreData,
            logout,
            updateUser,
            setUserCoreProps,
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};