import React from "react";
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const FormLayout = ({ children, title = '', onBack = '' }) => {
  const navigate = useNavigate();
  return (
    <Container className="px-5 py-5 hit-main-layout">
      {onBack !== '' && <a className="px-0 px-sm-5 pb-2 pb-sm-0 form-back-text" onClick={() => onBack !== '' ? navigate(onBack) : ''}>&lt; Back</a>}
      {title !== '' &&
        <Row className="justify-content-md-center">
          <Col>
            <span className='form-title'>{title}</span>
          </Col>
        </Row>}
      <Row className="justify-content-md-center">
        <Col xs={12} s={12} md={9} lg={8} xl={8} xxl={8} >
          {children}
        </Col>
      </Row>
    </Container >
  )
};

export default FormLayout;