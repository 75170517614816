import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useUser } from "../../hooks/useUser";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";

import ChangeRoleModal from '../../components/modal/ChangeRoleModal';
import Loader from "../../components/modal/Loader"

import GradientContainer from "../../components/cards/GradientContainer";
import GradientTable from "../../components/table/GradientTable";

import MainLayout from "../../components/layouts/MainLayout";
import TeamWidget from "../../components/widget/TeamWidget";

import Search from "../../components/Search";
import ModalLayout from '../../components/modal/ModalLayout';
import SendEmailReminderModal from "../../components/modal/SendEmailReminderModal";
import SendEmailReminderCard from "../../components/cards/SendEmailReminderCard";

import URLConstant from "../../constant/URLConstant";
import CONSTANTS from "../../constant/Constants";

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';
import FormButton from '../../components/buttons/FormButton';

var momentTZ = require('moment-timezone')

const SEARCH_LABEL = 'Search for players by email, first name or last name';

const DEFAULT_TEAM_WIDGETS = [
  {
    "id": 1,
    "title": "Baseline Tests Needed",
    "targetHierarchy": "team",
    "sequence": 1,
    "status": 1,
    "slug": "baseline-tests-needed"
  },
  {
    "id": 2,
    "title": "XXXX Benched",
    "targetHierarchy": "team",
    "sequence": 2,
    "status": 1,
    "slug": "players-benched"
  },
  {
    "id": 3,
    "title": "Post Injury Taken",
    "targetHierarchy": "team",
    "sequence": 3,
    "status": 1,
    "slug": "post-injury-tests-completed"
  }
];

const DEFAULT_SEARCH_FILTERS = {
  'baseline-tests-needed': false,
  'post-injury-tests-completed': false,
  'players-benched': false
};

const SEND_REMINDER_COPY = {

  'baseline-tests-needed': {
    MESSAGE_BODY: 'You still need to take your baseline test with HitCheck! Please login and complete your baseline test as soon as you can.',
    SUBJECT: {
      text1: 'All Team Athletes with a ',
      text2: '“Baseline Test Needed”',
      text3: ' status.'
    }
  },
};

function TeamDashboard() {

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const { leagueId = '', teamId = '' } = useParams();
  const { loadDashboardInfo } = useUser();

  const [members, setMembers] = useState([]);
  const [historicalData, setHistoricalDataInfo] = useState({});

  const [selectedWidgetSlug, setSelectedtWidgetSlug] = useState(true);
  const [widgetInfo, setWidgetInfo] = useState({ timestamp: Date.now(), widgets: DEFAULT_TEAM_WIDGETS });
  const [showSectionToSendBaselineReminder, setShowSectionToSendBaselineReminder] = useState(false);

  const [isMembersUpdated, setIsMembersUpdated] = useState(false);
  const [team, setTeam] = useState(false);
  const [roles, setRoles] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [teamAdmins, setTeamAdminsTableData] = useState([]);
  const [adminsTableTitle, setAdminsTableTitle] = useState('TEAM ADMINISTRATORS');
  const [playersTableTitle, setPlayersTableTitle] = useState('');

  const [aliasPlayerRole, setPlayerRoleAlias] = useState('');
  const [tableInfo, setTableInfo] = useState({
    headList: ['Name', 'Baseline', 'Actions'],
    actions: ['Test Scores', 'Profile', 'Remove', 'Change Role'],
  });

  const [showDashboardForPlayers, setShowDashboardForPlayers] = useState(false);
  const [showRemoveMemberConfirmationAlert, setShowRemoveMemberConfirmationAlert] = useState(false);
  const [showRosterArchiveConfirmationAlert, setShowRosterArchiveConfirmationAlert] = useState(false);

  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [searchFilters, setSearchFilters] = useState(DEFAULT_SEARCH_FILTERS);

  const [isModalVisible, setModalVisible] = useState(false);
  const [changeRoleModalInfo, setChangeRoleModalInfo] = useState({
    open: false,
  });

  const [reminderModalCopy, setReminderModalCopy] = useState(SEND_REMINDER_COPY['baseline-tests-needed']);
  const [emailReminderParams, setEmailReminderParams] = useState({});

  useEffect(() => {
    loadDashboardInfo();
    getTeamInfo();
    getUserRoles();
  }, []);

  useEffect(() => {
    getMembersData();
  }, [isMembersUpdated]);

  const getMembersData = async () => {

    try {

      setProcessing(true);
      setLoading(true);

      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        timezone: momentTZ.tz.guess()
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_MEMBERS, inputParams);

      const data = response?.data;
      if (data?.status) {
        setProcessing(false);
        setMembers(data.data);
        setTableData((data.data || []).filter((member) => member.roleId === CONSTANTS.PLAYER_OR_PATIENT_ROLE_ID)); // Set Players OR Patients record
        setTeamAdminsTableData((data.data || []).filter((member) => member.roleId === CONSTANTS.TEAM_ADMIN_ID)); // Set Players OR Patients record
        setIsMembersUpdated(false);
        // loadDataBasedOnFilter(searchFilters);
        setLoading(false);
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        setIsMembersUpdated(false);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const getTeamInfo = async () => {

    try {
      setProcessing(true);
      setLoading(true);
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM, inputParams);

      const data = response?.data;
      
      if (data?.status) {

        setTeam(data.data);

        // Player role users are not allowed to manage the team and its members. Just allow them to remove themselves from the team.
        if (data?.code == 'ACCESS_RESTRICTED') {
          setShowDashboardForPlayers(true);
          setProcessing(false);
          setLoading(false);
          return;
        };

        var baselineNeededCount = (parseInt(data?.data?.historicalData?.playerHeadcount || 0) - parseInt(data?.data?.historicalData?.numberOfMembersBaselineTaken || 0));

        if (data?.data?.widgets.length > 0) {
          setWidgetInfo({
            widgets: data?.data?.widgets,
            timestamp: Date.now()
          })
        };

        setPlayerRoleAlias((data?.data?.aliasForUserRole || 'Member'))
        setPlayersTableTitle(`${(data?.data?.aliasForUserRole || 'Member').toUpperCase()}S`);

        setShowSectionToSendBaselineReminder((baselineNeededCount > 0 ? true : false));
        setHistoricalDataInfo(data?.data?.historicalData || {})

        setProcessing(false);
        setLoading(false);

        return
      }
      else {
        // if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
    };
  };

  const getUserRoles = async () => {
    try {
      setProcessing(true);
      var inputParams = {
        roleType: 'team',
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.GET_TEAM_USER_ROLES, inputParams);

      const data = response?.data;
      if (data?.status) {
        setRoles(data.data.userRoles);
        setProcessing(false);
        return
      }
      else {
        // if (data?.message) alert(data.message);
        return;
      };

    } catch (err) {
      console.log(err)
      setProcessing(false);
    };
  };

  const handleRemoveMember = async (param) => {
    try {
      setLoading(true);
      var inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
        ...param,
      };
      const response = await postRequest(axiosPrivate, URLConstant.REMOVE_MEMBER, inputParams);

      const data = response?.data;
      if (data?.status) {
        alert(`${aliasPlayerRole} removed successfully!`);
        setIsMembersUpdated(true);
        getTeamInfo(); // We need to update the widget as well.
        return;
      }
      else {
        if (data?.message) alert(data.message);
        setLoading(false);
        return;
      };

    } catch (err) {
      console.log(err)
    }
  }

  const handleSearch = (event, searchTextValue) => {

    event.preventDefault();

    setSearchText(searchTextValue);
    setTableHeaderName(searchTextValue, searchFilters);

    loadDataBasedOnFilter(searchFilters, searchTextValue);

  }

  const setTableHeaderName = (searchTextValue, filters) => {

    if (!searchTextValue) {
      if (filters["baseline-tests-needed"]) {
        setPlayersTableTitle(`SEARCH RESULTS FOR "BASELINE TEST NEEDED" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`SEARCH RESULTS FOR "BASELINE TEST NEEDED" IN TEAM ADMINISTRATORS`);
      }
      else if (filters["players-benched"]) {
        setPlayersTableTitle(`SEARCH RESULTS FOR "BENCHED" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`SEARCH RESULTS FOR "BENCHED" IN TEAM ADMINISTRATORS`);
      }
      else if (filters["post-injury-tests-completed"]) {
        setPlayersTableTitle(`SEARCH RESULTS FOR "POST-INJURY TEST TAKEN" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`SEARCH RESULTS FOR "POST-INJURY TEST TAKEN" IN TEAM ADMINISTRATORS`);
      }
      else {
        setPlayersTableTitle(`${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`TEAM ADMINISTRATORS`);
      }
    }
    else if (searchTextValue) {
      if (searchTextValue && (filters["baseline-tests-needed"])) {
        setPlayersTableTitle(`SEARCH RESULTS FOR "${searchTextValue}" & "BASELINE TEST NEEDED" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`TEAM ADMINISTRATORS`);
      }
      else if (searchTextValue && (filters["players-benched"])) {
        setPlayersTableTitle(`SEARCH RESULTS FOR "${searchTextValue}" & "BENCHED" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`TEAM ADMINISTRATORS`);
      }
      else if (searchTextValue && (filters["post-injury-tests-completed"])) {
        setPlayersTableTitle(`SEARCH RESULTS FOR "${searchTextValue}" & "POST-INJURY TEST TAKEN" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`TEAM ADMINISTRATORS`);
      }
      else {
        setPlayersTableTitle(`SEARCH RESULTS FOR "${searchTextValue}" IN ${aliasPlayerRole.toUpperCase()}S`);
        setAdminsTableTitle(`SEARCH RESULTS FOR "${searchTextValue}" IN TEAM ADMINISTRATORS`);
      }
    }
    else {
      setPlayersTableTitle(`${aliasPlayerRole.toUpperCase()}S`);
      setAdminsTableTitle(`TEAM ADMINISTRATORS`);
    };
  }

  const handleAction = (event, action, param) => {

    event !== null && event.preventDefault();

    switch (action) {
      case 'test scores':
        navigate(`member/${param.teamMemberId}/user/${param.userID}/test/results`)
        break;
      case 'profile':
        navigate(`member/${param.teamMemberId}/profile/${param.userID}`)
        break;
      case 'remove':
        if (window.confirm(`Do you wish to remove the ${aliasPlayerRole}?`)) {
          handleRemoveMember(param);
        }
        break;
      case 'change role':
        setChangeRoleModalInfo({
          open: true,
          teamMemberId: param.teamMemberId,
          member: members.find((member) => member.teamMemberId === param.teamMemberId)
        });
        // handleRoleChange({ ...param, memberRoleSlug: event });
        break;
      case 'baseline-tests-needed':
      case 'players-benched':
      case 'post-injury-tests-completed':
        var updatedFilter = { ...DEFAULT_SEARCH_FILTERS, [action]: searchFilters?.[action] ? false : true };
        if (searchFilters?.[action]) {
          setSelectedtWidgetSlug('');
        }
        else {
          setSelectedtWidgetSlug(action);
        }
        setTableHeaderName(searchText, updatedFilter);
        setSearchFilters(updatedFilter);
        loadDataBasedOnFilter(updatedFilter, searchText);
        break
    }
  }

  const loadDataBasedOnFilter = (filter, searchTextValue) => {

    let textForSearch = searchTextValue?.trim()?.toLowerCase();

    /* Search Players/Patient Records */

    let filteredRecords = members.filter((member) => {
      if (
        (
          !textForSearch ||
          (
            member?.firstName?.toLowerCase().includes(textForSearch) ||
            member?.lastName?.toLowerCase().includes(textForSearch) ||
            member?.email?.toLowerCase().includes(textForSearch)
          )
        )
        &&
        (
          (filter?.['baseline-tests-needed'] && !member?.lastBaseline) ||
          (filter?.['players-benched'] && member?.benched) ||
          (filter?.['post-injury-tests-completed'] && member?.lastPostInjury) ||
          (
            !filter?.['baseline-tests-needed'] &&
            !filter?.['players-benched'] &&
            !filter?.['post-injury-tests-completed']
          )
        )
        &&
        member?.roleId == CONSTANTS.PLAYER_OR_PATIENT_ROLE_ID
      )
        return member
    });

    let teamAdminsFilteredRecords = members.filter((member) => {
      if (
        (
          !textForSearch ||
          (
            member?.firstName?.toLowerCase().includes(textForSearch) ||
            member?.lastName?.toLowerCase().includes(textForSearch) ||
            member?.email?.toLowerCase().includes(textForSearch)
          )
        )
        &&
        member?.roleId == CONSTANTS.TEAM_ADMIN_ID
      )
        return member
    });

    setTableData(filteredRecords);
    setTeamAdminsTableData(teamAdminsFilteredRecords);
    setTableInfo({
      headList: ['Name', 'Baseline', 'Actions'],
      actions: ['Test Scores', 'Profile', 'Remove', 'Change Role'],
    });

  }

  const handleAddMember = (event, title, addExistingMember = false) => {
    event.preventDefault();
    if (title === 'MEMBERS' || title === 'PATIENTS' || title === 'PLAYERS') {
      navigate(`/league/${leagueId}/team/${teamId}/create-team-member/?role=${team.aliasForUserRole}&memberType=${addExistingMember ? 'hitcheck-member' : 'new-member'}`);
    } else if (title === 'TEAM ADMINISTRATORS') {
      navigate(`/league/${leagueId}/team/${teamId}/create-team-member/?role=team_admin&memberType=${addExistingMember ? 'hitcheck-member' : 'new-member'}`)
    }
  }

  const handleSendReminderButton = async (event) => {
    event.preventDefault();
    setEmailReminderParams({
      teamId: +teamId,
      leagueId: +leagueId,
      reminderType: 'BASELINE_NEEDED',
      sendReminderAPI: URLConstant.TEAM_SEND_REMINDER,
    })
    setModalVisible(true)
  }

  const removeMemberFromTheTeam = async () => {

    setLoading(true);
    setShowRemoveMemberConfirmationAlert(false);

    try {
      
      let inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.REMOVE_MYSELF, inputParams);
      const data = response?.data;

      if (data?.message) alert(data.message);

      if (data?.status && data?.data) {
        await loadDashboardInfo(); // For updating the nav menu
        setLoading(false);
        navigate("/user/dashboard", { replace: true });
        return;
      }
      else {
        alert("Something went wrong!");
        return;
      };
    }
    catch (err) {
      setLoading(false);
    };
  };

  const handleRosterArchive = async () => {

    setLoading(true);

    try {
      
      let inputParams = {
        teamId: +teamId,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.ARCHIVE_ROSTER, inputParams);
      const data = response?.data;

      setShowRosterArchiveConfirmationAlert(false);
      setLoading(false);

      if (data?.message) alert(data.message);

      if (data?.status && data?.data) {
        setIsMembersUpdated(true);
        getTeamInfo(); // We need to update the widget as well.
        return;
      }
      else {
        alert("Something went wrong!");
        return;
      };
    }
    catch (err) {
      setLoading(false);
    };
  };

  const renderPlayerSection = () => {

    return (
      <>
        <GradientContainer
          cardType='team'
          title={team.name}
        />

        <Container className='mt-4'>
          <Row>
            <Col lg={12} className='mt-2'>
              <Stack>
                <ListGroup className="list-group-container">
                  <ListGroup.Item variant="secondary" className="list-group-gradient-head">
                    TEAM DASHBOARD
                  </ListGroup.Item>
                  <ListGroup.Item className="p-4 lh-lg">
                    <p className="text-left mb-4 profile-action-instruction">
                      You don't have sufficient permission to manage this team. Contact your Team Administrator for further details.
                    </p>
                    <FormButton title="Remove From Team" handleSubmit={() => setShowRemoveMemberConfirmationAlert(true)} isLoading={isProcessing} variant='white' skipCheckIcon/>
                  </ListGroup.Item>
                </ListGroup>
              </Stack>
            </Col>
          </Row>
        </Container>

        <ModalLayout
          open={showRemoveMemberConfirmationAlert}
          title="Confirm Action!"
          onClick={() => removeMemberFromTheTeam()}
          handleClose={() => setShowRemoveMemberConfirmationAlert(false)}
          buttonText={['cancel', 'confirm']}>
            <div className='px-4 py-3 modal-text' style={{fontSize: 18}}>Are you sure you want to remove your account from this team?</div>
        </ModalLayout>

        <Loader isLoading={isLoading}></Loader>
      </>
    );
  };

  if (showDashboardForPlayers) return renderPlayerSection();

  return (
    <>
      <GradientContainer
        cardType='team'
        title={team.name}
        pendingWithAdminApproval={historicalData?.pendingWithAdminApproval || false}
        editUrl={`/league/${leagueId}/team/${teamId}/edit-team`}
      />

      <MainLayout>

        <TeamWidget
          teamInfo={team}
          joinCode={team.joinCode}
          widgetInfo={widgetInfo}
          onAction={handleAction}
          selectedWidgetSlug={selectedWidgetSlug}
          playerRoleAlias={team.aliasForUserRole}
          setLoading={(loadingState) => setLoading(loadingState)}
        />

        <Search
          textValue={searchText}
          label={SEARCH_LABEL}
          onClick={handleSearch}
          isLoading={isProcessing}
          defaultSearchFilters={searchFilters}
          checkBoxOnChangeHandler={handleAction}
        />

        {
          searchFilters?.["baseline-tests-needed"] && showSectionToSendBaselineReminder &&
          <SendEmailReminderCard
            handleClick={handleSendReminderButton}
            isLoading={isLoading} />
        }

        <div className="table-container">
          <GradientTable
            title={playersTableTitle}
            rows={tableData}
            roles={roles}
            headList={tableInfo.headList}
            actions={tableInfo.actions}  //buttons passed as array
            onAction={handleAction}
            onAdd={handleAddMember}
            onArchive={() => setShowRosterArchiveConfirmationAlert(true)}
            isLoading={isProcessing}
          />
        </div>
        {
          (!searchFilters?.['baseline-tests-needed'] && !searchFilters?.['players-benched'] && !searchFilters?.['post-injury-tests-completed']) &&
          <div className="table-container">
            <GradientTable
              title={adminsTableTitle}
              rows={teamAdmins}
              roles={roles}
              headList={['Name', 'Baseline', 'Actions']}
              actions={['Test Scores', 'Profile', 'Remove', 'Change Role']}  //buttons passed as array
              onAction={handleAction}
              onAdd={handleAddMember}
              isLoading={isProcessing}
            />
          </div>
        }

        <SendEmailReminderModal
          open={isModalVisible}
          recipientsText={reminderModalCopy?.SUBJECT}
          message={reminderModalCopy?.MESSAGE_BODY}
          param={emailReminderParams}
          handleClose={(event) => {
            if (event) event.preventDefault();
            setModalVisible(false);
          }}
        />

        <ModalLayout
          open={showRosterArchiveConfirmationAlert}
          title="Confirm Action!"
          onClick={() => handleRosterArchive()}
          handleClose={() => setShowRosterArchiveConfirmationAlert(false)}
          buttonText={['cancel', 'confirm']}>
            <div className='px-4 pt-3 modal-text' style={{fontSize: 18}}>Are you sure you want to reset this team by archiving all its members?</div>
            <div className='px-4 pb-3 modal-text' style={{fontSize: 14}}>Note: You can still access the archived players from the "ROSTER ARCHIVES" menu section.</div>
        </ModalLayout>

        <ChangeRoleModal
          roles={roles}
          params={changeRoleModalInfo}
          handleClose={(event, isMemberUpdated = false) => {
            if (event) event.preventDefault();
            setChangeRoleModalInfo({ open: false });
            isMemberUpdated && setIsMembersUpdated(true);
          }} />

        <Loader isLoading={isLoading}></Loader>
      </MainLayout >
    </>
  );
}

export default TeamDashboard;