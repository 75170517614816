import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import CONSTANTS from "../../constant/Constants";
import URLConstant from "../../constant/URLConstant";

import axiosClient, { postRequest } from "../../helper/axios";
import { currencyFormatter } from '../../helper/util';

import Loader from "../../components/modal/Loader";
import TextField from "../tier-pricing/components/TextField";
import FormButton from '../../components/buttons/FormButton';

import '../../assets/styles/Checkout.css';

const Purchase = () => {

  const navigate = useNavigate();
  const queryParams = Object.fromEntries([...(new URLSearchParams(window.location.search))]);

  const [isLoading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState(null);
  const [memberCount, setMemberCount] = useState(1);
  const [totalPrice, setTotalPrice] = useState(null);

  useEffect(() => {
    getPackagePrice();
  }, [, memberCount]);

  const getPackagePrice = async () => {

    const inputParams = {
      subscriptionPlanId: queryParams?.['subscription-plan-id'] || '2', // Default: Basic plan
      headCount: memberCount?.toString()
    };

    if (!CONSTANTS?.LEAGUE_SUBSCRIPTION_PLAN_IDS?.includes(inputParams.subscriptionPlanId)) return;

    setLoading(true);

    const response = await postRequest(axiosClient, URLConstant.GET_PACKAGE_PRICE, inputParams);
    
    const data = response?.data;

    if (data?.data) {
      setPlanDetails(data?.data);
      console.log('memCount:: ', memberCount, 'totalPrice:: ', currencyFormatter(data?.data?.totalPrice || 0));
      setTotalPrice(currencyFormatter(data?.data?.totalPrice || 0));
      setLoading(false);
    };
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/purchase?subscription-plan-id=${queryParams?.['subscription-plan-id'] || '2'}&head-count=${memberCount}`);
  };

  const formatPrice = () => {

    var planPrice = planDetails?.costPerUser ? planDetails?.costPerUser?.toString().split('.') : [0];
    var formattedPriceJSX = [<span className="price-unit-text">$</span>, <span className="price-dollar-text">{planPrice[0]}</span>];

    if (planPrice[1]) {
      formattedPriceJSX.push(<span className="price-cent-text">.{planPrice[1]}</span>);
    };
    return <span>{formattedPriceJSX}</span>;
  };

  return (
    <>
      <div className="checkout-section-title">Customize Your Plan</div>
      <Container>
        {planDetails &&
          <Row className="checkout-wrapper align-items-center">
            <Col lg={6} className="p-0 p-sm-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="border-text-field-label" id="order-title">Plan Selected</div>
                <div className="order-summary-wrapper">
                  <div className="order-summary-inner-container">
                    <h1 className="plan-title-header">{planDetails?.title}</h1>
                    <div className="plan-sub-title" dangerouslySetInnerHTML={{__html: planDetails?.description?.replace(/\+/g, '<br/><span class="plus-sign">+</span>')}}></div>
                    <h3 className="plan-price">{formatPrice()}</h3>
                    <i className="mild-grey-text">per user annually</i>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5} className="checkout-form p-5">
              <h3 className="users-text text-center">How many athletes/patients will this plan serve?</h3>
              <div className="member-count-container">
                <TextField defaultValue={memberCount} onChange={(val) => setMemberCount(val)} />
              </div>
              <Row>
                <Col lg={8} md={12} className="d-flex align-items-center justify-content-center justify-content-lg-start">
                  <h3 className="mb-4 mb-lg-0 d-flex align-items-center">
                    <span className="users-text" style={{marginRight: 10}}>Total:</span>
                    {isLoading ?
                      <span class="spinner-border pricing-spinner" role="status" aria-hidden="true"></span> :
                      <span className="users-text">{totalPrice}</span>
                    }
                  </h3>
                </Col>
                <Col lg={4} md={12} className="d-flex align-items-center justify-content-center justify-content-lg-end">
                  <FormButton
                    title="Buy Now"
                    handleSubmit={handleSubmit}
                    //isLoading={isLoading}
                    skipCheckIcon
                    skipTextCapitalize
                    disabled={!memberCount}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={1}></Col>
          </Row>
        }
      </Container>
    </>
  );
}

export default Purchase;