import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import Container from 'react-bootstrap/Container';

import EditIcon from '../../../src/assets/icons/edit-icon.svg';
import AlertIcon from "../../assets/icons/alert-icon.svg";
import BorderButtonWithLink from "../buttons/BorderButtonWithLink";
import BorderButtonClickable from "../buttons/BorderButtonClickable";

import CONSTANTS from '../../constant/Constants';

const GradientContainer = (props) => {
  
  const { title = '', cardType = 'team', editUrl = '', pendingWithAdminApproval = false } = props;
  const { leagueId = '', teamId = '' } = useParams();
  const navigate = useNavigate();
  
  return (
    <Box sx={{
      background: 'linear-gradient(133.34deg, #0C243F 24.1%, #003F60 82.5%)',
      boxShadow: 1,
      minWidth: 300,
      // marginTop: '30px',
      padding: 0,
    }}>
      {
        pendingWithAdminApproval && cardType === 'team' &&
        <div className="user-acceptance-container">
          <Container fluid="sm">
            <img className="alert-icon" src={AlertIcon} alt="Edit" />
            <span className='awaiting-approval-text'>JOIN REQUESTS AWAITING APPROVAL</span>
            <BorderButtonWithLink 
              title='VIEW'
              colorCode={'#f65a4a'}
              handleClick={(event) => {
                event.preventDefault();
                navigate(`team-members-awaiting-for-approvals`);
              }}
            />
          </Container>
        </div>
      }
      <Container fluid="sm">
        {title !== '' ?
          <div className="dashboard-flex-between-container">
            <div>
              <span className="title-type-text" onClick={(event) => {
                event.preventDefault();
                window.location.reload(false);
              }}>
                {cardType.toUpperCase()}
              </span>
              <div className="dashboard-card-title-container">
                <h1 className="dashboard-card-title">
                  {title.toUpperCase()}
                </h1>
                {cardType === 'team' && editUrl &&
                  <span className="dashbord-edit" onClick={(event) => { event.preventDefault(); navigate(editUrl) }}>
                    <img className="edit-icon" src={EditIcon} alt="Edit" />EDIT
                  </span>
                }
              </div>
            </div>
            {(cardType === 'team' || cardType === 'league') &&
              <BorderButtonClickable title="MY ACCOUNT" size="large" handleClick={(event) => {
                event.preventDefault();
                navigate(`/user/profile/`)
              }} />
            }
            {((cardType === 'MY PROFILE' || cardType === "ATHLETE'S PROFILE") && props ?.avatar) &&
              <div style={styles.photoContainer}>
                <img src={`${CONSTANTS.CONFIG.AVATAR_PATH + props?.avatar}`} />
              </div>
            }
          </div>
          :
          <h1>
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
          </h1>
        }
      </Container>
    </Box>
  );
}

export default GradientContainer;

const styles = {
  
  photoContainer: {
    height: 100,
    width: 100,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    borderWidth: 2,
    borderColor: '#fff',
    borderStyle: 'solid',
    overflow: 'hidden',
    marginTop: '-1rem',
    marginBottom: '-1rem'
  }
}