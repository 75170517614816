import { useEffect } from "react";
import { axiosPrivate } from "../helper/axios";
import { useAuth } from "./useAuth";

const useAxiosPrivate = () => {

    const { coreData = {} } = useAuth();

    useEffect(() => {

        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization'] && coreData?.accessToken) {
                    config.headers['Authorization'] = `Bearer ${coreData?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
        }

    }, [coreData])

    return axiosPrivate;
}

export default useAxiosPrivate;
