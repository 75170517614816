import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputLabel from '@mui/material/InputLabel';

import CONSTANTS from "../../constant/Constants";
import URLConstant from "../../constant/URLConstant";

import axiosClient, { postRequest } from "../../helper/axios";
import { currencyFormatter } from '../../helper/util';
import { emailValidator, textValidator } from "../../helper/validator";

import Loader from "../../components/modal/Loader";
import FormButton from '../../components/buttons/FormButton';
import OrderSummary from './OrderSummary';

import '../../assets/styles/Checkout.css';
import HitAppIcon from '../../assets/images/hitcheck-app-icon.png';

import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(CONSTANTS.CONFIG.STRIPE_PUBLISHABLE_KEY);

const cardElementOptions = {
  style: {
    base: {
      fontFamily: 'Montserrat',
      color: '#fff',
      "::placeholder": {
        color: '#d6d6d6',
      }
    }
  },
};

const stripeOptions = {
  fonts: [{
    cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400,500',
  }]
};

const CheckoutForm = (props) => {

  // Stripe elements needs to be loaded in separate context wrt Elements provider. Hence load them on a separate component.
  const stripe = useStripe();
  const elements = useElements();
  const { setLoading, requestParams } = props;

  const [name, setName] = useState(requestParams?.name);
  const [email, setEmail] = useState(requestParams?.email);
  const [confirmEmail, setConfirmEmail] = useState(requestParams?.email);
  const [promoCode, setPromoCode] = useState(requestParams?.promoCode);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    
    event.preventDefault();

    if (textValidator(name)) {
      return setErrorMessage('Please enter a valid cardholder name.');
    }
    else if (emailValidator(email)) {
      return setErrorMessage('Please enter a valid email address.');
    }
    else if (emailValidator(confirmEmail) || (email != confirmEmail)) {
      return setErrorMessage('Email and confirm email doesn\'t match.');
    }
    else if (promoCode && textValidator(promoCode)) {
      return setErrorMessage('Please enter a valid promotional code.');
    };

    if (!stripe || !elements) {
      return; // Stripe.js hasn't yet loaded.
    };

    setLoading(true);
    setErrorMessage(null);

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: name,
        email: email,
      },
    });

    if (error) {
      console.error(error);
      setErrorMessage(error.message);
      setLoading(false);
      return;
    }
    else if (paymentMethod) {
      
      if (props.handlePurchase) {

        var inputParams = {
          paymentMethodId: paymentMethod?.id,
          name: name,
          email: email,
          promoCode: promoCode
        };

        if (props.setRequestParams) {
          await props.setRequestParams(inputParams);
        };
        await props.handlePurchase(inputParams, true);
      };
    };
  };

  return (
    <div>
      <div>
        <InputLabel className="border-text-field-label">Cardholder name</InputLabel>
        <input type="text" value={name} className="card-element" onChange={(e) => setName(e.target.value)}/>
      </div>
      <div>
        <InputLabel className="border-text-field-label">Card Number</InputLabel>
        <div className="card-element"><CardNumberElement options={{...cardElementOptions, ...{placeholder: 'XXXX XXXX XXXX XXXX'}}} /></div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="expiration-element">
          <InputLabel className="border-text-field-label">Expiration</InputLabel>
          <div className="card-element"><CardExpiryElement options={{...cardElementOptions, ...{placeholder: 'MM/YY'}}} /></div>
        </div>
        <div className="flex-fill">
          <InputLabel className="border-text-field-label">CVC</InputLabel>
          <div className="card-element"><CardCvcElement options={{...cardElementOptions, ...{placeholder: 'XXXX'}}} /></div>
        </div>
      </div>
      <div>
        <InputLabel className="border-text-field-label">Email Address</InputLabel>
        <input type="text" value={email} className="card-element" onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div>
        <InputLabel className="border-text-field-label">Confirm Email</InputLabel>
        <input type="text" value={confirmEmail} className="card-element" onChange={(e) => setConfirmEmail(e.target.value)} />
      </div>
      <div>
        <InputLabel className="border-text-field-label">Promo Code</InputLabel>
        <input type="text" value={promoCode} className="card-element" placeholder="Optional" onChange={(e) => setPromoCode(e.target.value?.toUpperCase())} />
      </div>
      {errorMessage && <div className="mt-4 checkout-error-message">{errorMessage}</div>}
      <div className="mt-5 d-flex justify-content-center justify-content-lg-end">
        <FormButton
          title="Purchase"
          handleSubmit={handleSubmit}
          skipCheckIcon
          skipTextCapitalize
        />
      </div>
    </div>
  );
};

const Purchase = () => {

  const [isLoading, setLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState(null);
  const [requestParams, setRequestParams] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [orderSummary, setOrderSummary] = useState(null);

  const queryParams = Object.fromEntries([...(new URLSearchParams(window.location.search))]);
  const subscriptionPlanId = queryParams?.['subscription-plan-id'] || '2'; // Default: Basic plan
  const headCount = queryParams?.['head-count'] || '1'; 
  
  useEffect(() => {
    getPackagePrice();
  }, []);

  const getPackagePrice = async (params, onPurchase = false) => {

    setLoading(true);
    setOrderSummary(null);

    const inputParams = {
      subscriptionPlanId: subscriptionPlanId,
      headCount: headCount,
      promoCode: params?.promoCode,
      subscriberEmail: params?.email
    };

    if (!CONSTANTS?.LEAGUE_SUBSCRIPTION_PLAN_IDS?.includes(inputParams.subscriptionPlanId)) return;

    const response = await postRequest(axiosClient, URLConstant.GET_PACKAGE_PRICE, inputParams);
    
    const data = response?.data;

    if (data?.data) {
      setPlanDetails(data?.data);
      if (onPurchase) {
        setOrderSummary(data?.data);
      };
    }
    else {
      if (data?.message) {
        alert(data?.message);
      };
    };
    setLoading(false);
  };

  const createSubscription = async () => {

    setLoading(true);

    var inputParams = {
      ...requestParams,
      subscriptionPlanId: parseInt(subscriptionPlanId),
      headCount: parseInt(headCount)
    };

    const response = await postRequest(axiosClient, URLConstant.CREATE_SUBSCRIPTION, inputParams);
    const data = response?.data;

    if (data?.data) {
      setResponseData(data.data);
    }
    else {
      if (data?.message) {
        alert(data?.message);
      };
    };
    setLoading(false);
  };

  if (responseData) {

    return (
      <div className="main-section league-widget-card checkout-wrapper">
        <img src={HitAppIcon} className="ms-5" alt="HITCHECK" width={190} height={60} />
        <div className="mt-5 border-text-field-label">Your account has been created.</div>
        <h3 className="mt-1 users-text">Welcome to the team!</h3>
        <div className="mt-4">
          <FormButton
            title="Complete Registration"
            handleSubmit={() => window.location = responseData?.registrationLink}
            skipCheckIcon
            skipTextCapitalize
          />
        </div>
      </div>
    );
  }
  else if (orderSummary) {
    return (
      <>
        <Loader isLoading={isLoading} />
        <div className="checkout-section-title">Order Summary</div>
        <OrderSummary
          orderSummary={orderSummary}
          handleBack={() => setOrderSummary(null)}
          handleSubmit={createSubscription}
        />
      </>
    )
  }
  else {
    return (
      <>
        <Loader isLoading={isLoading} />
        <div className="checkout-section-title">Review and Purchase</div>
        <Container>
          <Elements stripe={stripePromise} options={stripeOptions}>
            {planDetails &&
              <Row className="checkout-wrapper">
                <Col lg={6} className="p-0 p-sm-5">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="border-text-field-label" id="order-title">Your Order</div>
                    <div className="order-summary-wrapper">
                      <div className="order-summary-inner-container">
                        <h1 className="plan-title-header">{planDetails?.title}</h1>
                        <div className="plan-sub-title" dangerouslySetInnerHTML={{__html: planDetails?.description?.replace(/\+/g, '<br/><span class="plus-sign">+</span>')}}></div>
                        <div className="border-text-field-label"><i>Annual Plan Serving</i></div>
                        <h3 className="users-text">{planDetails?.headCount} Users</h3>
                      </div>
                      <div className="total-container">Total: {currencyFormatter(planDetails?.totalPrice || 0)}</div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="checkout-form p-4 p-sm-5">
                  <CheckoutForm
                    setLoading={setLoading}
                    requestParams={requestParams}
                    setRequestParams={setRequestParams}
                    handlePurchase={getPackagePrice}
                  />
                </Col>
              </Row>
            }
          </Elements>
        </Container>
      </>
    );
  }
}

export default Purchase;