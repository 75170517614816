import { Box, Button } from "@mui/material";
import React from "react";
import ContainedButton from "../buttons/ContainedButton";

const SendEmailReminderCard = (props) => {
  const { handleClick, isLoading } = props;
  return (
    <Box sx={{
      background: '#F5F5F5',
      border: '1px solid #003F60',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px 16px',
    }}>
      <span className="baseline-reminder-text">Send an email reminder to players who have not yet taken their baseline test.</span>
      <ContainedButton title="SEND REMINDER EMAIL" buttonType="send-email" hoverColor='#F65A4A' handleClick={handleClick} isLoading={isLoading} />
    </Box>
  )
}

export default SendEmailReminderCard;