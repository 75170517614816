import React from "react";
import Button from 'react-bootstrap/Button';

const FormButton = (props) => {

  const {
    title = "Submit",
    handleSubmit,
    type = "button",
    isLoading = false,
    size = "md",
    skipCheckIcon = false,
    fullWidth = false,
    variant = '',
    skipTextCapitalize = false,
    disabled = false
  } = props;

  const activeClassName = isLoading ? 'custom-active-btn' : '';

  return (
    <>
      <Button
        fullWidth={fullWidth}
        size={size}
        type="submit"
        variant="form-secondary"
        disabled={disabled}
        className={activeClassName}
        onClick={!isLoading ? handleSubmit : null}
      >
        {!isLoading && !skipCheckIcon && <span className="fa fa-check pe-3"></span>}
        {isLoading && <span className="spinner-grow spinner-grow-sm"></span>}
        <span className={size !== 'sm' ? (variant === 'white' ? 'button-text-white' : 'button-text') : 'button-text-sm'}>{("  " + (skipTextCapitalize ? title : title.toUpperCase()))} {isLoading && "..."}</span>
      </Button>
    </>
  )
};

export default FormButton;