/* Current Environment */
const ENV = process.env.NODE_ENV === 'development' ? 'DEV' : (process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging' ? 'STAGING' : 'PRODUCTION');
const AVATAR_ROOT_URL = 'https://s3.us-east-2.amazonaws.com/hitcheck-2.0/';

const APP_NAME = 'HITCHECK_WEB';
const APP_REF = `${APP_NAME}_${ENV}`;

const ENV_CONSTANT = {
    /* ENVIRONMENT */
    ENVIRONMENT: {
        DEV: {
            FRONTEND_BASE_URL: process.env.REACT_APP_REV_SSH_TUNNEL ? 'https://dev-app.hitcheck.com/' : 'http://localhost:3000/',
            BACKEND_BASE_URL: process.env.REACT_APP_REV_SSH_TUNNEL ? 'https://dev-bapp.hitcheck.com/' : 'http://localhost:1337/',
            PRICING_PAGE_URL: '/customize-plan?subscription-plan-id=4',
            AVATAR_PATH: AVATAR_ROOT_URL + 'dev/images/profiles/',
            GA_TRACKING_ID: 'G-2PZQL86VZ3',
            STRIPE_PUBLISHABLE_KEY: 'pk_test_51MEqZVBogOS2muTXRWAwJRS9vnjswcAPY6qT5CoYoArhwwXXENPvHJVqJc0cA7wReoDRzqM1OeIPSrABomgLlWe600C78vTdlr'
        },

        STAGING: {
            FRONTEND_BASE_URL: 'https://staging-app.hitcheck.com/',
            BACKEND_BASE_URL: 'https://staging-bapp.hitcheck.com/',
            PRICING_PAGE_URL: 'https://www.hitcheck.com/staging-pricing',
            AVATAR_PATH: AVATAR_ROOT_URL + 'staging/images/profiles/',
            GA_TRACKING_ID: 'G-2PZQL86VZ3',
            STRIPE_PUBLISHABLE_KEY: 'pk_test_51MEqZVBogOS2muTXRWAwJRS9vnjswcAPY6qT5CoYoArhwwXXENPvHJVqJc0cA7wReoDRzqM1OeIPSrABomgLlWe600C78vTdlr'
        },

        PRODUCTION: {
            FRONTEND_BASE_URL: 'https://app.hitcheck.com/',
            BACKEND_BASE_URL: 'https://bapp.hitcheck.com/',
            PRICING_PAGE_URL: 'https://www.hitcheck.com/pricing',
            AVATAR_PATH: AVATAR_ROOT_URL + 'production/images/profiles/',
            GA_TRACKING_ID: 'G-DY1SG61T0P',
            STRIPE_PUBLISHABLE_KEY: 'pk_live_it7PnIjjQaUvlWgMFzdLnWgR'
        },
    },
};

const DEFAULT_CONSTANT = {

    APP_NAME: APP_NAME,

    /* ASYNC STORAGE KEYS */
    ASYNC_STORAGE_KEYS: {
        USER_CORE_PROPERTIES: `${APP_REF}_APP_USER_PROPERTIES`,
        LEAGUE_DATA: `${APP_REF}_LEAGUE_DATA`,
        TEAMS_DATA: `${APP_REF}_TEAMS_DATA`,
        CORE_DATA: `${APP_REF}_CORE_DATA`,
        USER_SUBSCRIPTION_DATA: `${APP_REF}_USER_SUBSCRIPTION_DATA`,
    },

    SUBSCRIPTION_PACKAGE: {
        INDIVIDUAL: {
            CODE: 'INDIVIDUAL_PLAN',
            HIERARCHY: 'solo'
        }
    },

    LEAGUE_SUBSCRIPTION_PLAN_IDS: ['2', '3', '4'],

    PLAYER_OR_PATIENT_ROLE_ID: 3,

    TEAM_ADMIN_ID: 2,

    STATUS: {
        'ACTIVE': 1,
        'IN_ACTIVE': 2,
        'DELETED': 3,
        'SUSPENDED': 4,
        'EXPIRED': 5,
    },

    SEARCH_LABEL: 'Search for players by email, first or last name',
    ADMIN_SEARCH_LABEL: 'Search for users by username, email, first or last name',
    ADMIN_LEAGUE_SEARCH_LABEL: 'Search for leagues by name',

    CUSTOM_SUBSCRIPTION_TEXT:'Customized integration for large leagues, clubs and schools systems.',
    CUSTOM_SUBSCRIPTION_PRICING_TEXT:'Volume Discount Pricing',

};


DEFAULT_CONSTANT.CONFIG = ENV_CONSTANT.ENVIRONMENT[ENV];

export default DEFAULT_CONSTANT;


