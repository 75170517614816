import React, { useState } from "react";
import { useNavigate, useParams ,} from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import { useFormValidator } from "../../hooks/useFormValidator";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import URLConstant from "../../constant/URLConstant";

import GradientButton from "../../components/buttons/GradientButton";
import Container from 'react-bootstrap/Container';
import FormLayout from '../../components/layouts/FormLayout';
import CustomFormTextField from '../../components/textfields/CustomTextField';
import FormButton from '../../components/buttons/FormButton';

const CreateTeam = () => {

  const menuItem = {
    currentMenu: {
      title: 'Create Team'
    },
  };

  const { leagueId = '' } = useParams();
  const navigate = useNavigate();

  const gaEventTracker = useAnalyticsEventTracker('CREATE-TEAM');
  const axiosPrivate = useAxiosPrivate();

  const [form, setForm] = useState({
    teamName: ""
  });
  const [isProcessing, setProcessing] = useState(false);
  const { errors, validateForm, onBlurField } = useFormValidator(form);

  const onUpdateField = e => {

    const field = 'teamName';

    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };

    setForm(nextFormState);

    if (errors?.[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleSubmit = async (event) => {

    event.preventDefault();

    const { errors: finalError } = await validateForm({ form, errors, forceTouchErrors: true });

    if (finalError?.teamName?.error) return;

    handleCreateTeam();

  };

  const handleCreateTeam = async () => {

    try {

      setProcessing(true);

      var inputParams = {
        ...form,
        leagueId: +leagueId,
      };

      const response = await postRequest(axiosPrivate, URLConstant.CREATE_TEAM, inputParams);

      const data = response?.data;
      if (data?.status) {
        gaEventTracker('CREATE_TEAM_BUTTON', 'CREATE_TEAM');
        setProcessing(false);
        alert('Team created successfully!');
        setForm({
          teamName: ""
        })
        if(data?.data){
          navigate(`/league/${data?.data?.leagueId}/team/${data?.data?.id}/`)
        }
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
      gaEventTracker('ERROR', 'CREATE_TEAM');
    };
  };
  return (

    <FormLayout title="Create Team" onBack={`/league/${leagueId}`}>
        <Row className="mt-5 justify-content-md-center">
          <Col lg={12} >
            <CustomFormTextField
              id="teamName"
              label="Team Name"
              placeholder="Required"
              value={form.teamName}
              errorData={errors?.teamName}
              onChange={onUpdateField}
              onBlur={onBlurField}
            />
            <Stack direction="horizontal" gap={3}>
              <br></br>
            </Stack>
            <Stack direction="vertical" gap={3} className="mx-auto mt-4">
              <FormButton title="Create Team" handleSubmit={handleSubmit} isLoading={isProcessing} skipCheckIcon/>
            </Stack>
          </Col>
        </Row>
    </FormLayout >
  );
};

export default CreateTeam;