import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';

import DashboardMainLayout from "../../components/layouts/DashboardMainLayout";
import ProfileInfoCard from "../../components/cards/Profile";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";

function MemberProfile() {

  const { leagueId = '', teamId = '', userID = '', memberId = '' } = useParams();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(false);
  
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {

    try {

      setLoading(true);

      var inputParams = {
        userID: +userID,
        teamId: +teamId,
        leagueId: +leagueId,
        memberId: +memberId
      };

      const response = await postRequest(axiosPrivate, URLConstant.MEMBER_PROFILE, inputParams);

      const data = response?.data;
      if (data?.status && data?.data) {
        setUser(data?.data);
      } else {
        if (data?.message) alert(data.message);
        setLoading(false)
        return;
      };

      setLoading(false);
      return
    } catch (err) {
      console.log(err)
    };
  };

  return (

    <DashboardMainLayout title="Profile Info">
      <div className="px-3 py-3 lh-lg">
        {
          isLoading &&
          <>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
          </>
        }
        {
          !isLoading && user.email && <ProfileInfoCard user={user} hideVerify={true} />
        }
      </div>
    </DashboardMainLayout>
  );
};

export default MemberProfile;