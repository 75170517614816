import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';

import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from '../../components/DatePicker';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import { useFormValidator } from "../../hooks/useFormValidator";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

import URLConstant from "../../constant/URLConstant";
import CustomFormTextField from '../../components/textfields/CustomTextField';
import FormLayout from '../../components/layouts/FormLayout';
import FormButton from '../../components/buttons/FormButton';

const DEFAULT_FORM_FIELDS = {
  email: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  gender: "male",
};

const CreateLeagueAdmin = () => {

  const { leagueId = '' } = useParams();
  const navigate = useNavigate();

  var queryParams = Object.fromEntries([...(new URLSearchParams(window.location.search))]);
  const memberType = queryParams?.memberType == 'hitcheck-member' ? 2 : 1;

  const axiosPrivate = useAxiosPrivate();

  const [form, setForm] = useState(DEFAULT_FORM_FIELDS);
  const [isProcessing, setProcessing] = useState(false);
  const { errors, validateForm, onBlurField } = useFormValidator(form);
  const gaEventTracker = useAnalyticsEventTracker('CREATE-LEAGUE-ADMIN');

  const onUpdateField = e => {

    const field = e.target.name;

    const nextFormState = {
      ...form,
      [field]: e.target.value,
    };

    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const onUpdateByField = (fieldName, value) => {
    const field = fieldName;

    const nextFormState = {
      ...form,
      [field]: value,
    };

    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };

  const handleSubmit = (event) => {

    event.preventDefault();

    validateForm({ form, errors, forceTouchErrors: true });

    if (memberType == 1) {
      if (!form.firstName || !form.lastName || !form.email || !form.gender || !form.birthDate) return;
      else if (errors?.firstName?.error || errors?.lastName?.error || errors?.email?.error || errors?.gender?.error || errors?.birthDate?.error) return;
    }
    else if (memberType == 2) {
      if (!form.email) return;
      else if (errors?.email?.error) return;
    };

    handleCreateLeagueAdmin();
  };

  const handleCreateLeagueAdmin = async () => {

    try {

      setProcessing(true);

      var formValues = form;

      if (moment.isMoment(form.birthDate)) {
        formValues.birthDate = form.birthDate.format('YYYY-MM-DD');
      }
      else {
        formValues.birthDate = moment.utc(form.birthDate).format('YYYY-MM-DD');
      }

      var inputParams = {
        leagueId: +leagueId,
        userInfo: JSON.stringify(formValues),
        memberRoleSlug: queryParams?.['role'] || 'league_admin',
        memberType: memberType
      };

      const response = await postRequest(axiosPrivate, URLConstant.CREATE_LEAGUE_ADMIN, inputParams);

      const data = response?.data;

      if (data?.status) {

        alert('League Admin added successfully!')
        gaEventTracker('CREATE_BUTTON', 'CREATE-LEAGUE-ADMIN');

        setForm({ ...DEFAULT_FORM_FIELDS })
        setProcessing(false);
        navigate(`/league/${leagueId}`)
        return
      }
      else {
        if (data?.message) alert(data.message);
        setProcessing(false);
        return;
      };

    } catch (err) {
      console.log(err)
      gaEventTracker('ERROR', 'CREATE-LEAGUE-ADMIN');
    };
  };

  return (
    <FormLayout title={`${memberType == 2 ? 'Add' : 'Create'} League Administrator`} onBack={`/league/${leagueId}`}>

      <div className='mt-4'></div>

      {memberType == 1 && (
        <Row className="mt-1 justify-content-md-center">
          <Col lg={12} >
            <CustomFormTextField
              id="firstName"
              label="First Name"
              placeholder="Required"
              errorData={errors?.firstName}
              value={form.firstName}
              onChange={onUpdateField}
              onBlur={onBlurField}
            />
            <Stack direction="horizontal" gap={1}>
              <br></br>
            </Stack>
          </Col>
        </Row>
      )}

      {memberType == 1 && (
        <Row className=" mt-1 justify-content-md-center">
          <Col lg={12} >
            <CustomFormTextField
              id="lastName"
              label="Last Name"
              placeholder="Required"
              errorData={errors?.lastName}
              value={form.lastName}
              onChange={onUpdateField}
              onBlur={onBlurField} />
            <Stack direction="horizontal" gap={3}>
              <br></br>
            </Stack>
          </Col>
        </Row>
      )}

      <Row className=" mt-1 justify-content-md-center">
        <Col lg={12} >
          <CustomFormTextField
            id="email"
            label="Email"
            placeholder="Required"
            errorData={errors?.email}
            value={form.email}
            onChange={onUpdateField}
            onBlur={onBlurField}
          />
          <Stack direction="horizontal" gap={3}>
            <br></br>
          </Stack>
        </Col>
      </Row>

      {memberType == 1 && (
        <Row className=" mt-1 justify-content-md-center">
          <Col lg={12} >
            <DatePicker
              label="Birth Date"
              placeholder="Required"
              value={form.birthDate}
              error={errors?.birthDate?.error ? true : false}
              helperText={errors.birthDate.dirty && errors.birthDate.error ? errors.birthDate.message : ""}
              onChange={
                (value) => onUpdateByField('birthDate', value)
              }
              mobile />
            <Stack direction="horizontal" gap={3}>
              <br></br>
            </Stack>
          </Col>
        </Row>
      )}

      {memberType == 1 && (
        <Row className=" mt-1 justify-content-md-center">
          <Col lg={12} >
            <FormControl fullWidth className="form-control-radio-button-group">
              <FormLabel id="gender-label">Gender</FormLabel>
              <RadioGroup
                row
                className='form-control-radio'
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={'male'}
                name="gender"
                variant="filled"
                error={errors?.gender?.error ? true : false}
                onChange={onUpdateField}
                value={form.gender || null}
                size="small"
              >
                <FormControlLabel value="male" className="form-control-radio-label" control={<Radio size="small" />} label="Male" />
                <FormControlLabel value="female" className="form-control-radio-label" control={<Radio size="small" />} label="Female" />
                <FormControlLabel value="non-binary" className="form-control-radio-label" control={<Radio size="small" />} label="Non-binary" />
              </RadioGroup>
            </FormControl>
            {(errors?.gender?.dirty && errors?.gender?.error) &&
              <Stack direction="horizontal" gap={3}>
                <p className="text-danger css-1wc848c-MuiFormHelperText-root">{errors.gender?.message}</p>
              </Stack>
            }
          </Col>
        </Row>
      )}

      <Row className=" mt-1 justify-content-md-center">
        <Col lg={12} >
          <Stack direction="vertical" gap={3} className="mx-auto mt-4">
            <FormButton title={`${memberType == 2 ? 'Add' : 'Create'} League Administrator`} handleSubmit={handleSubmit} isLoading={isProcessing} skipCheckIcon />
          </Stack>
        </Col>
      </Row>
    </FormLayout>
  )
};

export default CreateLeagueAdmin;