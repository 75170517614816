import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function SimpleBackdrop(props) {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        handleToggle(props.isLoading)
    }, [props.isLoading]);

    const handleToggle = (flag) => {
        setOpen(flag);
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    background: 'rgba(1, 46, 69, 0.9)',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <CircularProgress color='success' />
            </Backdrop>
        </div>
    );
}
