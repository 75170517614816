import React, { useState } from "react";
import { useParams } from "react-router";
import Box from '@mui/material/Box';
import Form from 'react-bootstrap/Form';
import Skeleton from 'react-loading-skeleton'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { postRequest } from "../../helper/axios";
import URLConstant from "../../constant/URLConstant";

import ModalLayout from './ModalLayout';

const ChangeRoleModal = (props) => {

  const { params, handleClose, roles } = props;
  const { leagueId = '' } = useParams();
  const axiosPrivate = useAxiosPrivate();

  const [role, setRole] = useState(params.member?.roleId);
  const [isLoading, setLoading] = useState(false);

  React.useEffect(() => {
    setRole(params?.member?.roleId?.toString() || null)
  }, [params?.member?.roleId])

  React.useEffect(() => {
    if (!params.open) setRole(null)
  }, [params.open])

  const onChangeRole = async (event) => {

    event.preventDefault();
    var newRoleSlug = await roles.find((item) => role == item?.id)?.slug;

    try {
      
      if (!newRoleSlug) {
        alert('Choose Role and click Save.' +newRoleSlug);
        return;
      };

      setLoading(true);

      var inputParams = {
        teamId: params.member.teamId,
        leagueId: +leagueId,
        teamMemberId: params.teamMemberId,
        memberRoleSlug: newRoleSlug,
        // teamMemberId: +teamMemberId,
        // memberRoleSlug: memberRoleSlug,
      };

      const response = await postRequest(axiosPrivate, URLConstant.CHANGE_ROLE, inputParams);

      const data = response?.data;
      if (data?.status) {
        alert('Role Changed successfully!');
        setLoading(false);
        setRole(null);
        props.handleClose(event, true);
        return
      }
      else {
        if (data?.message) alert(data.message);
        setLoading(false);
        return;
      };

    } catch (err) {
      console.log(err)
    }
  }
  return (
    <ModalLayout
      open={params.open}
      onClick={onChangeRole}
      title='CHANGE ROLE'
      isLoading={isLoading}
      handleClose={handleClose}
      buttonText={['cancel', 'save']}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '25px 50px 20px 50px',
      }}>
        {/* <Form className="change-role-form">
          <Form.Label className="modal-sub-title">Assign this User's role :</Form.Label>
          {roles.map((item) => <>
            <Form.Check.Input
              id="change-role-checkbox"
              type="radio"
              // checked={item?.id === params.member?.roleId}
              onChange={(event) => {
                // event.persist();
                console.log('item',item)
                setRole(item.slug)
              }}
            />
           <Form.Check.Label>{role.item}</Form.Check.Label>
          </>
          )}
        </Form>  */}

        <FormControl className='change-role-radio'>
          <FormLabel >{
            params?.member?.firstName ?
            <span className='modal-text modal-text-bold'>{`Assign ${params?.member?.firstName} ${params?.member?.lastName}'s role : `}</span>
            : <span className='modal-text modal-text-bold'> <Skeleton animation="wave" /></span>
            }
            </FormLabel>
          <RadioGroup
            onChange={(event) => setRole(event.target.value)}
            value={(role || null)}
          >
            {roles.map((item) => <FormControlLabel
              key={'change-role-'+item.id}
              value={item.id}
              label={item.title}
              disabled={item.id === params.member?.roleId}
              control={<Radio />}
            />)}
          </RadioGroup>
        </FormControl>
      </Box>
    </ModalLayout >
  )
}

export default ChangeRoleModal;