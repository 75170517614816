import React, { Suspense } from "react";

import Route from "./router/Route";
import usePageTracking from "./hooks/usePageTracking";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/styles/style.scss';
import './assets/styles/App.css';

function App() {

  usePageTracking();

  return (
    <Suspense fallback={<div className="loader"></div>}>
      <ToastContainer />
      <Route />
    </Suspense>
  );
}

export default App;
