import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/system';

const StyledTextField = styled(OutlinedInput,
  {
    name: "StylesTextField"
  })
  ({
    "& .MuiInputBase-input": {
      backgroundColor: '#D9D9D9',
      borderRadius: '4px',
      padding: '10px 15px',
      fontFamily: 'Montserrat',
    },
  });

const CustomFormTextField = (props) => {
  
  const onValueChange = (e) => {
    const { name, value } = e.target;
    if ((name == "email" || name == "username") && value) {
      // Replace smart single quote with regular apostrophe to support user email containing apostrophe in it. 
      // https://developer.apple.com/forums/thread/89706
      e.target.value = value.replace(/[\u2018\u2019]/g, "'");
    };
    props.onChange(e);
  };
  
  return (
    <>
      <InputLabel className="border-text-field-label-white">{props.label}</InputLabel>
      <StyledTextField
        fullWidth
        id={props.id}
        type={props.type}
        name={props.id}
        // label={props.label}
        placeholder={props.placeholder}
        error={
          props?.errorData?.dirty && props?.errorData?.error ? true : undefined
        }
        helperText={
          props?.errorData?.dirty && props?.errorData?.error
            ? props?.errorData?.message
            : ''
        }
        value={props.value}
        onChange={onValueChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      // variant="filled"
      // sx={{
      //   "& .MuiInputBase-root": {
      //     background: '#d9d9d9',
      //     height:'40px',
      //     border:'none',
      //   }
      // }}
      />
      {props?.errorData?.dirty && props?.errorData?.error && props?.errorData?.message &&
        <div className='mt-1 label-error'>{props?.errorData?.message}</div>
      }
    </>
  );
};

export default CustomFormTextField;
