import React, { useEffect } from "react";
import ModalLayout from '../modal/ModalLayout';

const HubSpotContactForm = props => {
    
  const { showContactForm , onClose} = props;
    
  useEffect(() => {
    
    if (!showContactForm) return;

    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '7222852',
          formId: 'c786e5e9-2f5a-465b-8be3-7f10f0e1c651',
          target: '#hubspotForm'
        });
      }
    });
  }, [showContactForm]);

  const FormBody = () => {

    return (
      <ModalLayout
        open={showContactForm}
        title="Need help creating your HitCheck plan?"
        showCloseIcon={true}
        handleClose={() => onClose()}>
          <div style={styles.formContainer} className='hubspot-form-container'>
            <p className='contact-modal-instruction'>Fill out the short form below and a HitCheck representative will be in touch to help you find the perfect plan for your program.</p>
            <div id="hubspotForm"></div>
          </div>
      </ModalLayout>
    );
  };

  return (
    <FormBody />
  );
};

export default HubSpotContactForm;

const styles = {
  
  formContainer: {
    minHeight: 200,
    maxHeight: 500,
    width: 700,
    overflow: 'auto',
    background: '#fff',
    padding: 20,
    marginBottom: -25
  }
}