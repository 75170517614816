import React, { useState } from 'react';
import UpArrowIcon from '../../../assets/icons/up-arrow-icon.png';
import DownArrowIcon from '../../../assets/icons/down-arrow-icon.png';

const TextField = (props) => {

  const { defaultValue = 1, onChange, disabled } = props;
  const [value, setValue] = useState(defaultValue);

  const onValueChange = (value) => {
    if (value < 0) return;
    setValue(value);
    onChange(value);
  };

  return (
    <span className='ps-3 d-inline-flex flex-row justify-content-start'>
      <input
        className='tier-pricing-input'
        disabled={disabled}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => onValueChange(parseInt(e.target.value))}
        type='number'
        min={1}
      />
      <div className='ps-2 d-flex flex-column justify-content-between my-1'>
        <img src={UpArrowIcon}
          width='18px'
          height='auto'
          alt="incriment"
          onClick={(e) => {
            e.preventDefault()
            onValueChange(value + 1);
          }} />
        <img src={DownArrowIcon}
          width='18px'
          height='auto'
          alt="decriment"
          onClick={(e) => {
            e.preventDefault();
            onValueChange(value - 1);
          }} />
      </div>
    </span>
  );
};

export default TextField;
