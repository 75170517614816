import { isValidPhoneNumber, getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import AthletesIcon from '../assets/icons/athletes-icon.svg';
import BaselineIcon from '../assets/icons/baseline-icon.svg';
import PendingJoinRequestIcon from '../assets/icons/pending-join-requests-icon.svg';
import PostInjuryIcon from '../assets/icons/post-injury-tests-icon.svg';
import BenchedIcon from '../assets/icons/total-benched-icon.svg';
import currentBenchedIcon from '../assets/icons/current-benched-icon.svg';
import _ from "lodash";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const getParsedPhoneNumber = async (phone) => {

  if (phone && (await isValidPhoneNumber(phone))) {

    var parsedPhoneNumber = parsePhoneNumber(phone)
    var phoneNumber = phone;

    if (parsedPhoneNumber) {

      var data = {}
      var countryCode = parsedPhoneNumber.country;
      var callingCode = '+';
      callingCode += await getCountryCallingCode(parsedPhoneNumber.country);

      /* Strip spaces, -, brackets and callingCode from phone number */
      phoneNumber = phoneNumber.replace(/[^\d\+]/g, '')
      phoneNumber = phoneNumber.replace(callingCode, '')

      data.phone = phoneNumber;
      data.countryCode = countryCode;
      data.callingCode = callingCode;

      return data;
    };
  }
  return null;
}

export const downLoadFile = async (data, fileName, type = 'application/csv') => {

  var blobObj = new Blob([data], { type });
  const href = URL.createObjectURL(blobObj);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');

  link.href = href;
  link.setAttribute('download', fileName); //or any other extension

  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  return null;
}

export const getDurationValues = (durationType) => {
  var values = [];
  switch (durationType) {
    case 'day':
      for (var i = 1; i <= 100; i++) {
        values.push(i);
      }
      break;
    case 'month': 
    for (var i = 1; i <= 12; i++) {
      values.push(i);
    }
      break;
    case 'year':
      for (var i = 1; i <= 5; i++) {
        values.push(i);
      }
      break;
    default:
      break;
  }
  return values;
}

export const getLeagueWidgetCardInfo = (title) => {
  let info = [
    {
      title:"total-players" ,
      // icon: require('../assets/icons/athletes-icon.svg'),
      icon: AthletesIcon,
    },
    {
      title:"baseline-tests-needed",
      // icon: require('../assets/icons/baseline-icon.svg'),
      icon: BaselineIcon,
    },
    {
      title:"pending-join-requests" ,
      // icon: require('../assets/icons/pending-join-requests-icon.svg'),
      icon:PendingJoinRequestIcon,
    },
    {
      title:"post-injury-tests-completed" ,
      // icon: require('../assets/icons/post-injury-tests-icon.svg'),
      icon:PostInjuryIcon,
    },
    {
      title:'total-benched-players' ,
      // icon: require('../assets/icons/total-benched-icon.svg'),
      icon:BenchedIcon,
    },
    {
      title:'players-benched' ,
      // icon: require('../assets/icons/current-benched-icon.svg'),
      icon:currentBenchedIcon,
    },
  ]
  return info.find((item)=> item.title === title);
}

export const currencyFormatter = (amount) => {
  return(formatter.format(amount));
}

export const getDefaultLeagueOrTeam = (leagues, teams) => {
  
  let teamOrLeagueDetails = {};
  let admininstrableTeams = teams?.filter((team) => team.isAdmin === true);

  if (leagues?.length >= 1) {
    teamOrLeagueDetails = {  // 
      leagueId: leagues[0].id
    }
  }
  else if (admininstrableTeams?.length >= 1) {
    teamOrLeagueDetails = {
      leagueId: admininstrableTeams[0].leagueId,
      teamId: admininstrableTeams[0].id,
    };
  }
  console.log('getDefaultLeagueOrTeam :', teamOrLeagueDetails);
  return teamOrLeagueDetails;
};

export const fmtMSS = (s) => {
  s = Math.trunc(s);
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};

export const customRound = (number) => {
  const roundedToDecimal = Math.round(number * 10) / 10; // Round to one decimal point
  return Math.round(roundedToDecimal); // Round to nearest integer
};

export const processNormativeValuesForUser = (normativeValuesForUser) => {

  if (!_.isEmpty(normativeValuesForUser)) {

    var processedNormativeValuesForUser = {};

    processedNormativeValuesForUser['testMemory'] = `${customRound(normativeValuesForUser['stm_l'])}%-${customRound(normativeValuesForUser['stm_u'])}%`;
    processedNormativeValuesForUser['testBalance'] = `${customRound(normativeValuesForUser['bal_l'])}%-${customRound(normativeValuesForUser['bal_u'])}%`;
    processedNormativeValuesForUser['testReaction'] = `${parseFloat(normativeValuesForUser['rxn_l']).toFixed(2)}s-${parseFloat(normativeValuesForUser['rxn_u']).toFixed(2)}s`;
    processedNormativeValuesForUser['testCar'] = `${customRound(normativeValuesForUser['coord_l'])}%-${customRound(normativeValuesForUser['coord_u'])}%`;
    processedNormativeValuesForUser['testVisual'] = `${customRound(normativeValuesForUser['patrec_l'])}%-${customRound(normativeValuesForUser['patrec_u'])}%`;
    processedNormativeValuesForUser['testSequence'] = `${customRound(normativeValuesForUser['probsol_l'])}%-${customRound(normativeValuesForUser['probsol_u'])}%`;
    processedNormativeValuesForUser['testColor'] = `${customRound(normativeValuesForUser['color_l'])}%-${customRound(normativeValuesForUser['color_u'])}%`;
    processedNormativeValuesForUser['testImpulse'] = `${customRound(normativeValuesForUser['impulse_l'])}%-${customRound(normativeValuesForUser['impulse_u'])}%`;
    processedNormativeValuesForUser['testMemoryLongTerm'] = `${customRound(normativeValuesForUser['ltm_l'])}%-${customRound(normativeValuesForUser['ltm_u'])}%`;

    return processedNormativeValuesForUser;
  }
  else return null;
};