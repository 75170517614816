import React, { Suspense, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Skeleton from '@mui/material/Skeleton';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormLayout from '../components/layouts/FormLayout';

const DeepLinkingFallback = () => {

  var queryParams = Object.fromEntries([...(new URLSearchParams(window.location.search))]);
  const navigate = useNavigate();

  useEffect(() => {
    /* Navigate to join code page. */
    if (queryParams?.joinCode && queryParams?.type === 'JOIN_TEAM_WITH_CODE')
      navigate('/team-join-code?joinCode=' + queryParams?.joinCode);
  }, [queryParams?.joinCode, queryParams?.type]);

  return (
    <FormLayout>
      <Suspense fallback={<Skeleton count={5} />}>
        <Row className="mt-5 justify-content-md-center">
          <Col lg={12} >

            <span className='mb-3 d-flex intruction-text-green justify-content-center' style={{fontSize: 23}}>Mobile link only!</span>

            <p className="mt-4 legal-note text-center" style={{fontSize: 20, lineHeight: 1.5}}>Please open this link <b>from your mobile</b> and complete the automated action triggered by the HitCheck mobile app.</p>

            <p className="mt-4 legal-note">If you don’t have the HitCheck mobile app installed on your device, please use the following instructions to get up and running quickly:</p>
            
            <ol className="legal-note">
              <li className="mb-2">Use the appropriate button below to install the HitCheck app on your device.</li>
              <li className="mb-2">Then, <b>go back to your email</b> and use the provided link to launch the app and complete the required action there.</li>
            </ol>

            <div className='d-flex align-center mt-3'>
              <a href='https://apps.apple.com/us/app/hitcheck-cognitive-assessment/id1061769052' style={styles.badgeContainer}><img src={process.env.PUBLIC_URL + "/assets/images/apple-store-badge-white.png"} style={styles.badgeIcon} /></a>
              <a href='https://play.google.com/store/apps/details?id=com.jabico.hhitt' style={{ ...styles.badgeContainer, marginTop: 15 }}><img src={process.env.PUBLIC_URL + "/assets/images/play-store-badge-white.png"} style={styles.badgeIcon} /></a>
            </div>

          </Col>
        </Row>
      </Suspense>
    </FormLayout>
  )
}

export default DeepLinkingFallback;

const styles = {

  badgeContainer: {
    margin: 8
  },
  badgeIcon: {
    width: 120,
    height: 49
  }
}